import { gql } from "@apollo/client";

export const VARIABLE_TIME_RANGE_DISCOUNT_AND_PRICE_FRAGMENT = gql`
	fragment VariableTimeRangeDiscountAndPriceFragment on VariableTimeRangeDiscount {
		id
		discount
		price
	}
`;

export const VARIABLE_TIME_RANGE_DISCOUNT_FRAGMENT = gql`
	fragment VariableTimeRangeDiscountFragment on VariableTimeRangeDiscount {
		id
		packId
		variableTimeRange {
			id
		}
		discount
	}
`;

export const VARIABLE_TIME_RANGE_DISCOUNT_FULL_FRAGMENT = gql`
	fragment VariableTimeRangeDiscountFullFragment on VariableTimeRangeDiscount {
		id
		packId
		variableTimeRange {
			id
		}
		discount
		price
		totalPrice
	}
`;
