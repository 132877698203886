import { gql } from "@apollo/client";
import { VOLUME_DISCOUNT_RANGE_FRAGMENT } from "@graphQl/fragments/volumeRangeDiscountFragment";

export const INDEX_VOLUME_RANGE_FRAGMENT = gql`
	fragment IndexVolumeRangeFragment on VolumeRange {
		id
		index
	}
`;

export const FROM_VOLUME_RANGE_FRAGMENT = gql`
	fragment FromVolumeRangeFragment on VolumeRange {
		id
		from
	}
`;

export const TO_VOLUME_RANGE_FRAGMENT = gql`
	fragment ToVolumeRangeFragment on VolumeRange {
		id
		to
	}
`;

export const ERROR_VOLUME_RANGE_FRAGMENT = gql`
	fragment ErrorVolumeRangeFragment on VolumeRange {
		id
		error
	}
`;

export const IS_DEFAULT_SELECTED_VOLUME_RANGE_FRAGMENT = gql`
	fragment IsDefaultSelectedVolumeRangeFragment on VolumeRange {
		id
		isDefaultSelected
	}
`;

export const DESCRIPTION_VOLUME_RANGE_FRAGMENT = gql`
	fragment DescriptionVolumeRangeFragment on VolumeRange {
		id
		description
	}
`;

export const VOLUME_RANGE_DEPENDENCIES_FRAGMENT = gql`
	fragment VolumeRangeDependenciesFragment on VolumeRange {
		id
		dependencies {
			requiredId
			desiredId
			parentDependencyId
			requiredRange {
				... on VolumeRange {
					id
				}
				... on EngagementRange {
					id
				}
				... on PaymentTermRange {
					id
				}
				... on SpecialRange {
					id
				}
			}
			desiredRange {
				... on VolumeRange {
					id
				}
				... on EngagementRange {
					id
				}
				... on PaymentTermRange {
					id
				}
				... on SpecialRange {
					id
				}
			}
		}
	}
`;

export const TO_AND_ERROR_VOLUME_RANGE_FRAGMENT = gql`
	${TO_VOLUME_RANGE_FRAGMENT}
	${ERROR_VOLUME_RANGE_FRAGMENT}
	fragment ToAndErrorVolumeRangeFragment on VolumeRange {
		...ToVolumeRangeFragment
		...ErrorVolumeRangeFragment
	}
`;

export const FROM_TO_ERROR_VOLUME_RANGE_FRAGMENT = gql`
	${FROM_VOLUME_RANGE_FRAGMENT}
	${TO_VOLUME_RANGE_FRAGMENT}
	${ERROR_VOLUME_RANGE_FRAGMENT}
	fragment FromToErrorVolumeRangeFragment on VolumeRange {
		...FromVolumeRangeFragment
		...ToVolumeRangeFragment
		...ErrorVolumeRangeFragment
	}
`;

export const INDEX_FROM_TO_ERROR_VOLUME_RANGE_FRAGMENT = gql`
	${INDEX_VOLUME_RANGE_FRAGMENT}
	${FROM_VOLUME_RANGE_FRAGMENT}
	${TO_VOLUME_RANGE_FRAGMENT}
	${ERROR_VOLUME_RANGE_FRAGMENT}
	fragment IndexFromToErrorVolumeRangeFragment on VolumeRange {
		...IndexVolumeRangeFragment
		...FromVolumeRangeFragment
		...ToVolumeRangeFragment
		...ErrorVolumeRangeFragment
	}
`;

export const FROM_TO_VOLUME_RANGE_WITH_VOLUME_DISCOUNT_FRAGMENT = gql`
	${FROM_VOLUME_RANGE_FRAGMENT}
	${TO_VOLUME_RANGE_FRAGMENT}
	fragment FromToVolumeRangeFragment on VolumeRange {
		...ToVolumeRangeFragment
		...FromVolumeRangeFragment
		volumeDiscount {
			id
			basedOn
			unitMetric
			pluralUnitMetric
		}
	}
`;

export const FROM_TO_VOLUME_RANGE_FRAGMENT = gql`
	${FROM_VOLUME_RANGE_FRAGMENT}
	${TO_VOLUME_RANGE_FRAGMENT}
	fragment FromToVolumeRangeFragment on VolumeRange {
		...ToVolumeRangeFragment
		...FromVolumeRangeFragment
	}
`;

export const VOLUME_RANGE_FRAGMENT = gql`
	${VOLUME_DISCOUNT_RANGE_FRAGMENT}
	${FROM_VOLUME_RANGE_FRAGMENT}
	${VOLUME_RANGE_DEPENDENCIES_FRAGMENT}
	${IS_DEFAULT_SELECTED_VOLUME_RANGE_FRAGMENT}
	fragment VolumeRangeFragment on VolumeRange {
		...FromVolumeRangeFragment
		...VolumeRangeDependenciesFragment
		...IsDefaultSelectedVolumeRangeFragment
		volumeDiscountId
		index
		to
		description
		error
		uuid
		volumeDiscountRange {
			...VolumeDiscountRangeFragment
		}
	}
`;
