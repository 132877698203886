import { gql } from "@apollo/client";
import {
	ENGAGEMENT_RANGE_DISCOUNT_FULL_FRAGMENT,
	ENGAGEMENT_RANGE_DISCOUNT_PACK_ID_AND_DISCOUNT_FRAGMENT,
} from "@graphQl/fragments/engagementRangeDiscount";

export const DURATION_ENGAGEMENT_RANGE_FRAGMENT = gql`
	fragment DurationEngagementRangeFragment on EngagementRange {
		id
		duration
	}
`;

export const INDEX_ENGAGEMENT_RANGE_FRAGMENT = gql`
	fragment IndexEngagementRangeFragment on EngagementRange {
		id
		index
	}
`;

export const IS_DEFAULT_SELECTED_ENGAGEMEMENT_RANGE_FRAGMENT = gql`
	fragment IsDefaultSelectedEngagementRangeFragment on EngagementRange {
		id
		isDefaultSelected
	}
`;

export const ENGAGEMENT_RANGE_DESCRIPTION_FRAGMENT = gql`
	fragment DescriptionEngagementRangeFragment on EngagementRange {
		id
		description
	}
`;

export const ENGAGEMENT_RANGE_DEPENDENCIES_FRAGMENT = gql`
	fragment EngagementRangeDependenciesFragment on EngagementRange {
		id
		dependencies {
			requiredId
			desiredId
			parentDependencyId
			requiredRange {
				... on VolumeRange {
					id
				}
				... on EngagementRange {
					id
				}
				... on PaymentTermRange {
					id
				}
				... on SpecialRange {
					id
				}
			}
			desiredRange {
				... on VolumeRange {
					id
				}
				... on EngagementRange {
					id
				}
				... on PaymentTermRange {
					id
				}
				... on SpecialRange {
					id
				}
			}
		}
	}
`;

export const ENGAGEMENT_RANGE_FRAGMENT = gql`
	${ENGAGEMENT_RANGE_DISCOUNT_PACK_ID_AND_DISCOUNT_FRAGMENT}
	fragment EngagementRangeFragment on EngagementRange {
		id
		uuid
		engagementDiscountId
		duration
		index
		engagementDiscountRange {
			...EngagementRangeDiscountPackIdAndDiscountFragment
		}
	}
`;

export const ENGAGEMENT_RANGE_FULL_FRAGMENT = gql`
	${ENGAGEMENT_RANGE_DISCOUNT_FULL_FRAGMENT}
	${ENGAGEMENT_RANGE_DEPENDENCIES_FRAGMENT}
	${IS_DEFAULT_SELECTED_ENGAGEMEMENT_RANGE_FRAGMENT}
	${INDEX_ENGAGEMENT_RANGE_FRAGMENT}
	${ENGAGEMENT_RANGE_DESCRIPTION_FRAGMENT}
	fragment EngagementRangeFullFragment on EngagementRange {
		id
		uuid
		engagementDiscountId
		duration
		...IndexEngagementRangeFragment
		...IsDefaultSelectedEngagementRangeFragment
		...EngagementRangeDependenciesFragment
		...DescriptionEngagementRangeFragment
		engagementDiscountRange {
			...EngagementRangeDiscountFullFragment
		}
	}
`;
