import { gql } from "@apollo/client";
import { VOLUME_RANGE_FRAGMENT } from "@graphQl/fragments/volumeRangeFragment";
import { VOLUME_DISCOUNT_LINK_VOLUME_DISCOUNTS_FRAGMENT } from "./volumeDiscountLink";

export const BASED_ON_VOLUME_DISCOUNT_FRAGMENT = gql`
	fragment BasedOnVolumeDiscountFragment on VolumeDiscount {
		id
		basedOn
	}
`;

export const TIME_METRIC_VOLUME_DISCOUNT_FRAGMENT = gql`
	fragment TimeMetricDiscountFragment on VolumeDiscount {
		id
		timeMetric
	}
`;

export const UNIT_METRIC_VOLUME_DISCOUNT_FRAGMENT = gql`
	fragment UnitMetricVolumeDiscountFragment on VolumeDiscount {
		id
		unitMetric
	}
`;

export const IS_DECREASING_VOLUME_DISCOUNT_FRAGMENT = gql`
	fragment IsDecreasingVolumeDiscountFragment on VolumeDiscount {
		id
		isDecreasing
	}
`;

export const DISPLAYED_AS_VOLUME_DISCOUNT_FRAGMENT = gql`
	fragment DisplayedAsVolumeDiscountFragment on VolumeDiscount {
		id
		displayedAs
	}
`;

export const DISPLAY_PRICE_PER_UNIT_VOLUME_DISCOUNT_FRAGMENT = gql`
	fragment DisplayPricePerUnitVolumeDiscountFragment on VolumeDiscount {
		id
		displayPricePerUnit
	}
`;

export const UNIT_METRICS_VOLUME_DISCOUNT_FRAGMENT = gql`
	fragment UnitMetricsDiscountFragment on VolumeDiscount {
		id
		unitMetric
		pluralUnitMetric
		uuid
	}
`;

export const TYPE_VOLUME_DISCOUNT_FRAGMENT = gql`
	fragment TypeVolumeDiscountFragment on VolumeDiscount {
		id
		type
	}
`;

export const VOLUME_DISCOUNT_VOLUME_DISCOUNT_LINK_FRAGMENT = gql`
	${VOLUME_DISCOUNT_LINK_VOLUME_DISCOUNTS_FRAGMENT}
	fragment VolumeDiscountVolumeDiscountLinkFragment on VolumeDiscount {
		id
		volumeDiscountLinkId
		volumeDiscountLink {
			...VolumeDiscountLinkVolumeDiscountsFragment
		}
	}
`;

export const VOLUME_DISCOUNT_FRAGMENT = gql`
	${VOLUME_RANGE_FRAGMENT}
	${BASED_ON_VOLUME_DISCOUNT_FRAGMENT}
	${TYPE_VOLUME_DISCOUNT_FRAGMENT}
	${VOLUME_DISCOUNT_VOLUME_DISCOUNT_LINK_FRAGMENT}
	fragment VolumeDiscountFragment on VolumeDiscount {
		...BasedOnVolumeDiscountFragment
		createdAt
		createdBy
		id
		decimalsNumber
		updatedAt
		updatedBy
		basedOn
		unitMetric
		pluralUnitMetric
		timeMetric
		isDecreasing
		displayedAs
		uuid
		...VolumeDiscountVolumeDiscountLinkFragment
		volumeRange {
			...VolumeRangeFragment
		}
		isLocked
		...TypeVolumeDiscountFragment
	}
`;

export const VOLUME_DISCOUNT_DATA_FOR_VOLUME_DEPENDENCY_FRAGMENT = gql`
	fragment VolumeDiscountDataForVolumeDependencyFragment on VolumeDiscount {
		id
		volumeDiscountLinkId
		unitMetric
	}
`;
