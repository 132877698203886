import { gql } from "@apollo/client";
import { SCHEDULED_MAIL_MAIL_FRAGMENT } from "./scheduledMail";
import { ROLE_FRAGMENT } from "@graphQl/fragments/role";

export const USER_UUID_FRAGMENT = gql`
	fragment UserUUIDFragment on User {
		id
		uuid
	}
`;

export const USER_USERNAME_FRAGMENT = gql`
	fragment UserUserNameFragment on User {
		id
		username
	}
`;

export const USER_EMAIL_FRAGMENT = gql`
	fragment UserEmailFragment on User {
		id
		email
	}
`;

export const USER_CREATED_AT_FRAGMENT = gql`
	fragment UserCreatedAtFragment on User {
		id
		createdAt
	}
`;

export const USER_SURNAME_FRAGMENT = gql`
	fragment UserSurnameFragment on User {
		id
		surname
	}
`;

export const USER_LASTNAME_FRAGMENT = gql`
	fragment UserLastnameFragment on User {
		id
		lastname
	}
`;

export const USER_DEFAULT_WORKSPACE_FRAGMENT = gql`
	fragment UserDefaultWorkspaceFragment on User {
		id
		defaultWorkspace
	}
`;

export const USER_LANGUAGE_FRAGMENT = gql`
	fragment UserLanguageFragment on User {
		id
		language
	}
`;

export const USER_CURRENCY_FRAGMENT = gql`
	fragment UserCurrencyFragment on User {
		id
		currency
	}
`;

export const USER_IMAGE_PATH_FRAGMENT = gql`
	fragment UserImagePathFragment on User {
		id
		imagePath
	}
`;

export const USER_IS_ROOT_FRAGMENT = gql`
	fragment UserIsRootFragment on User {
		id
		isRoot
	}
`;

export const USER_ACTIVE_FRAGMENT = gql`
	fragment UserActiveFragment on User {
		id
		active
	}
`;

export const USER_PHONE_FRAGMENT = gql`
	fragment UserPhoneFragment on User {
		id
		phone
	}
`;

export const USER_THEME_FRAGMENT = gql`
	fragment UserThemeFragment on User {
		id
		theme
	}
`;

export const USER_PENDING_MAIL_FRAGMENT = gql`
	fragment UserPendingMailFragment on User {
		id
		pendingMail
	}
`;

export const USER_FUNCTION_FRAGMENT = gql`
	fragment UserFunctionFragment on User {
		id
		function
	}
`;

export const CONNECTION_OWNER_FRAGMENT = gql`
	${USER_EMAIL_FRAGMENT}
	${USER_SURNAME_FRAGMENT}
	${USER_LASTNAME_FRAGMENT}
	fragment ConnectionOwnerFragment on User {
		id
		...UserEmailFragment
		...UserSurnameFragment
		...UserLastnameFragment
	}
`;

export const USER_FRAGMENT = gql`
	${ROLE_FRAGMENT}
	${USER_USERNAME_FRAGMENT}
	${USER_EMAIL_FRAGMENT}
	${USER_CREATED_AT_FRAGMENT}
	${USER_SURNAME_FRAGMENT}
	${USER_LASTNAME_FRAGMENT}
	${USER_DEFAULT_WORKSPACE_FRAGMENT}
	${USER_LANGUAGE_FRAGMENT}
	${USER_CURRENCY_FRAGMENT}
	${USER_IMAGE_PATH_FRAGMENT}
	${USER_IS_ROOT_FRAGMENT}
	${USER_ACTIVE_FRAGMENT}
	${USER_PHONE_FRAGMENT}
	${USER_THEME_FRAGMENT}
	${USER_PENDING_MAIL_FRAGMENT}
	${USER_FUNCTION_FRAGMENT}
	${SCHEDULED_MAIL_MAIL_FRAGMENT}
	fragment UserFragment on User {
		...UserUserNameFragment
		...UserEmailFragment
		...UserSurnameFragment
		...UserLastnameFragment
		...UserDefaultWorkspaceFragment
		...UserLanguageFragment
		...UserCurrencyFragment
		...UserImagePathFragment
		...UserIsRootFragment
		...UserActiveFragment
		...UserPhoneFragment
		...UserThemeFragment
		...UserPendingMailFragment
		...UserFunctionFragment
		scheduledJoinMail {
			...ScheduledMailMailFragment
		}
		role {
			...RoleFragment
		}
		...UserCreatedAtFragment
	}
`;
export const LOGIN_USER_FRAGMENT = gql`
	${USER_EMAIL_FRAGMENT}
	${USER_USERNAME_FRAGMENT}
	${USER_SURNAME_FRAGMENT}
	${USER_LASTNAME_FRAGMENT}
	${USER_IMAGE_PATH_FRAGMENT}
	${USER_CURRENCY_FRAGMENT}
	${USER_LANGUAGE_FRAGMENT}
	${USER_DEFAULT_WORKSPACE_FRAGMENT}
	${USER_IS_ROOT_FRAGMENT}
	${USER_ACTIVE_FRAGMENT}
	fragment LoginUserFragment on User {
		...UserEmailFragment
		...UserUserNameFragment
		...UserSurnameFragment
		...UserLastnameFragment
		...UserImagePathFragment
		...UserCurrencyFragment
		...UserLanguageFragment
		...UserDefaultWorkspaceFragment
		...UserIsRootFragment
		...UserActiveFragment
	}
`;
