import { gql } from "@apollo/client";

export const VOLUME_DISCOUNT_RANGE_KEY_FIELDS_FRAGMENT = gql`
	fragment VolumeDiscountRangeKeyFieldsFragment on VolumeRangeDiscount {
		id
		packId
		volumeRangeId
	}
`;

export const VOLUME_DISCOUNT_RANGE_PRICE_FRAGMENT = gql`
	fragment VolumeDiscountRangePriceFragment on VolumeRangeDiscount {
		id
		price
		discount
	}
`;

export const VOLUME_DISCOUNT_RANGE_ENABLE_DISCOUNT_FRAGMENT = gql`
	${VOLUME_DISCOUNT_RANGE_KEY_FIELDS_FRAGMENT}
	fragment VolumeDiscountRangeEnableDiscountFragment on VolumeRangeDiscount {
		...VolumeDiscountRangeKeyFieldsFragment
		enableDiscount
	}
`;

export const VOLUME_RANGE_DISCOUNT_DISCOUNT_FRAGMENT = gql`
	${VOLUME_DISCOUNT_RANGE_KEY_FIELDS_FRAGMENT}
	fragment VolumeRangeDiscountDiscountFragment on VolumeRangeDiscount {
		...VolumeDiscountRangeKeyFieldsFragment
		otherDiscount
		discount
	}
`;

export const VOLUME_RANGE_DISCOUNT_DISCOUNT_OTHER_FRAGMENT = gql`
	${VOLUME_DISCOUNT_RANGE_KEY_FIELDS_FRAGMENT}
	fragment VolumeRangeDiscountDiscountOtherFragment on VolumeRangeDiscount {
		...VolumeDiscountRangeKeyFieldsFragment
		discount
		priceFirstLineDifference
		otherDiscount
	}
`;

export const VOLUME_RANGE_DISCOUNT_PRICE_FIRST_LINE_DIFFERENCE_FRAGMENT = gql`
	${VOLUME_DISCOUNT_RANGE_KEY_FIELDS_FRAGMENT}
	fragment VolumeRangeDiscountPriceFirstLineDifferenceFragment on VolumeRangeDiscount {
		...VolumeDiscountRangeKeyFieldsFragment
		priceFirstLineDifference
	}
`;

export const VOLUME_RANGE_DISCOUNT_DIFFERENCE_PREVIOUS_LINE_FRAGMENT = gql`
	${VOLUME_DISCOUNT_RANGE_KEY_FIELDS_FRAGMENT}
	fragment VolumeRangeDiscountDifferencePreviousLineFragment on VolumeRangeDiscount {
		...VolumeDiscountRangeKeyFieldsFragment
		diffPreviousRange
	}
`;

export const VOLUME_DISCOUNT_RANGE_DISCOUNT_ENABLE_DISCOUNT_FRAGMENT = gql`
	${VOLUME_DISCOUNT_RANGE_ENABLE_DISCOUNT_FRAGMENT}
	fragment VolumeDiscountRangeDiscountEnableDiscountFragment on VolumeRangeDiscount {
		id
		packId
		volumeRangeId
		discount
		...VolumeDiscountRangeEnableDiscountFragment
	}
`;

export const VOLUME_DISCOUNT_RANGE_FRAGMENT = gql`
	${VOLUME_DISCOUNT_RANGE_ENABLE_DISCOUNT_FRAGMENT}
	${VOLUME_RANGE_DISCOUNT_DIFFERENCE_PREVIOUS_LINE_FRAGMENT}
	${VOLUME_RANGE_DISCOUNT_DISCOUNT_OTHER_FRAGMENT}
	${VOLUME_RANGE_DISCOUNT_PRICE_FIRST_LINE_DIFFERENCE_FRAGMENT}
	fragment VolumeDiscountRangeFragment on VolumeRangeDiscount {
		id
		packId
		volumeRangeId
		discount
		warning
		price
		...VolumeRangeDiscountDiscountOtherFragment
		...VolumeRangeDiscountDifferencePreviousLineFragment
		...VolumeRangeDiscountPriceFirstLineDifferenceFragment
		...VolumeDiscountRangeEnableDiscountFragment
	}
`;
