import { gql } from "@apollo/client";
import { VARIABLE_TIME_RANGE_DISCOUNT_FULL_FRAGMENT } from "./variableTimeRangeDiscount";

export const DURATION_VARIABLE_TIME_RANGE_FRAGMENT = gql`
	fragment DurationVariableTimeRangeFragment on VariableTimeRange {
		id
		duration
	}
`;

export const VARIABLE_TIME_RANGE_FRAGMENT = gql`
	${VARIABLE_TIME_RANGE_DISCOUNT_FULL_FRAGMENT}
	fragment VariableTimeRangeFragment on VariableTimeRange {
		id
		variableTimeDiscountId
		duration
		index
		isUnlimited
		variableTimeRangeDiscounts {
			...VariableTimeRangeDiscountFullFragment
		}
	}
`;
