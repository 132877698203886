import { gql } from "@apollo/client";

export const NUMERICAL_RANGE_IS_BOOSTER_ALLOWED_FRAGMENT = gql`
	fragment NumericalRangeIsBoosterAllowedFragment on NumericalRange {
		id
		isBoosterAllowed
	}
`;

export const NUMERICAL_RANGE_FROM_NAME_UNLIMITED_FRAGMENT = gql`
	fragment NumericalRangeFromNameUnlimitedFragment on NumericalRange {
		id
		name
		from
		isUnlimited
	}
`;

export const NUMERICAL_RANGE_FROM_FRAGMENT = gql`
	fragment NumericalRangeFromFragment on NumericalRange {
		id
		from
	}
`;

export const NUMERICAL_RANGE_NAME_FRAGMENT = gql`
	fragment NumericalRangeNameFragment on NumericalRange {
		id
		name
	}
`;

export const NUMERICAL_RANGE_TO_FRAGMENT = gql`
	fragment NumericalRangeToFragment on NumericalRange {
		id
		to
	}
`;

export const NUMERICAL_RANGE_MEDIA_URL_FRAGMENT = gql`
	fragment NumericalRangeMediaUrlFragment on NumericalRange {
		id
		mediaURL
	}
`;

export const NUMERICAL_RANGE_LINK_FRAGMENT = gql`
	fragment NumericalRangeLinkFragment on NumericalRange {
		id
		link
	}
`;

export const NUMERICAL_RANGE_INDEX_FRAGMENT = gql`
	fragment NumericalRangeIndexFragment on NumericalRange {
		id
		index
	}
`;

export const NUMERICAL_RANGE_SCORE_FRAGMENT = gql`
	fragment NumericalRangeScoreFragment on NumericalRange {
		id
		score
	}
`;

export const NUMERICAL_RANGE_PRICE_FRAGMENT = gql`
	fragment NumericalRangePriceFragment on NumericalRange {
		id
		price
	}
`;

export const NUMERICAL_RANGE_COST_FRAGMENT = gql`
	fragment NumericalRangeCostFragment on NumericalRange {
		id
		cost
	}
`;

export const NUMERICAL_RANGE_PROFIT_FRAGMENT = gql`
	fragment NumericalRangeProfitFragment on NumericalRange {
		id
		profit
	}
`;

export const NUMERICAL_RANGE_DESCRIPTION_FRAGMENT = gql`
	fragment NumericalRangeDescriptionFragment on NumericalRange {
		id
		description
	}
`;

export const NUMERICAL_RANGE_IS_UNLIMITED_FRAGMENT = gql`
	fragment NumericalRangeIsUnlimitedFragment on NumericalRange {
		id
		isUnlimited
	}
`;

export const NUMERICAL_RANGE_FRAGMENT = gql`
	${NUMERICAL_RANGE_FROM_NAME_UNLIMITED_FRAGMENT}
	${NUMERICAL_RANGE_SCORE_FRAGMENT}
	${NUMERICAL_RANGE_IS_BOOSTER_ALLOWED_FRAGMENT}
	${NUMERICAL_RANGE_INDEX_FRAGMENT}
	${NUMERICAL_RANGE_PRICE_FRAGMENT}
	${NUMERICAL_RANGE_COST_FRAGMENT}
	${NUMERICAL_RANGE_PROFIT_FRAGMENT}
	${NUMERICAL_RANGE_DESCRIPTION_FRAGMENT}
	${NUMERICAL_RANGE_IS_UNLIMITED_FRAGMENT}
	${NUMERICAL_RANGE_TO_FRAGMENT}
	${NUMERICAL_RANGE_MEDIA_URL_FRAGMENT}
	${NUMERICAL_RANGE_LINK_FRAGMENT}
	fragment NumericalRangeFragment on NumericalRange {
		id
		numericalId
		...NumericalRangeFromNameUnlimitedFragment
		...NumericalRangeToFragment
		...NumericalRangeScoreFragment
		...NumericalRangeIsBoosterAllowedFragment
		...NumericalRangePriceFragment
		...NumericalRangeCostFragment
		...NumericalRangeProfitFragment
		...NumericalRangeIndexFragment
		...NumericalRangeDescriptionFragment
		...NumericalRangeIsUnlimitedFragment
		...NumericalRangeMediaUrlFragment
		...NumericalRangeLinkFragment
		errors {
			id
			from
		}
	}
`;

export const PUBLIC_NUMERICAL_RANGE_FRAGMENT = gql`
	${NUMERICAL_RANGE_FROM_FRAGMENT}
	${NUMERICAL_RANGE_SCORE_FRAGMENT}
	${NUMERICAL_RANGE_IS_BOOSTER_ALLOWED_FRAGMENT}
	${NUMERICAL_RANGE_INDEX_FRAGMENT}
	${NUMERICAL_RANGE_PRICE_FRAGMENT}
	${NUMERICAL_RANGE_COST_FRAGMENT}
	${NUMERICAL_RANGE_DESCRIPTION_FRAGMENT}
	${NUMERICAL_RANGE_IS_UNLIMITED_FRAGMENT}
	${NUMERICAL_RANGE_TO_FRAGMENT}
	${NUMERICAL_RANGE_MEDIA_URL_FRAGMENT}
	${NUMERICAL_RANGE_LINK_FRAGMENT}
	${NUMERICAL_RANGE_NAME_FRAGMENT}
	fragment PublicNumericalRangeFragment on NumericalRange {
		id
		numericalId
		...NumericalRangeFromFragment
		...NumericalRangeToFragment
		...NumericalRangeScoreFragment
		...NumericalRangeIsBoosterAllowedFragment
		...NumericalRangePriceFragment
		...NumericalRangeCostFragment
		...NumericalRangeIndexFragment
		...NumericalRangeDescriptionFragment
		...NumericalRangeIsUnlimitedFragment
		...NumericalRangeMediaUrlFragment
		...NumericalRangeLinkFragment
		...NumericalRangeNameFragment
	}
`;
