import { gql } from "@apollo/client";
import {
	PRICE_COMMUNICATION_DISPLAYED_GENERAL_TITLE_FRAGMENT,
	PRICE_COMMUNICATION_FLAG_FRAGMENT,
	PRICE_MODEL_DISPLAYED_COMMITMENT_DEFAULT_FRAGMENT,
	PRICE_MODEL_ENABLE_SECONDARY_TIME_METRIC_FRAGMENT,
	PRICE_MODEL_PRIMARY_METRICS_FRAGMENT,
	PRICE_MODEL_PRIMARY_TIME_METRIC_FRAGMENT,
	PRICE_MODEL_SECONDARY_METRICS_FRAGMENT,
	PRICE_MODEL_SECONDARY_TIME_METRIC_FRAGMENT,
} from "@graphQl/fragments/priceCommunication";

import {
	PRICE_MODEL_ARE_FEATURE_DESCRIPTIONS_WRAPPED_FRAGMENT,
	PRICE_MODEL_CORNER_RADIUS_FRAGMENT,
	PRICE_MODEL_DESCRIPTIVE_NULL_DISPLAY_FRAGMENT,
	PRICE_MODEL_DESIGN_AND_SETTING_ADDON_OPENED_BY_DEFAULT_AFTER_FRAGMENT,
	PRICE_MODEL_DESIGN_AND_SETTING_ADDON_OPENED_BY_DEFAULT_BEFORE_FRAGMENT,
	PRICE_MODEL_DESIGN_AND_SETTING_ADDON_SECTION_NAME_AFTER_FRAGMENT,
	PRICE_MODEL_DESIGN_AND_SETTING_ADDON_SECTION_NAME_BEFORE_FRAGMENT,
	PRICE_MODEL_DESIGN_AND_SETTING_EXTRA_OPENED_BY_DEFAULT_AFTER_FRAGMENT,
	PRICE_MODEL_DESIGN_AND_SETTING_EXTRA_OPENED_BY_DEFAULT_BEFORE_FRAGMENT,
	PRICE_MODEL_DESIGN_AND_SETTING_EXTRA_SECTION_NAME_AFTER_FRAGMENT,
	PRICE_MODEL_DESIGN_AND_SETTING_EXTRA_SECTION_NAME_BEFORE_FRAGMENT,
	PRICE_MODEL_DESIGN_AND_SETTING_IS_ROUND_UP_ABOVE_THOUSAND_FRAGMENT,
	PRICE_MODEL_DISPLAYED_VAT_DEFAULT_FRAGMENT,
	PRICE_MODEL_DS_SHOW_SAVINGS_FRAGMENT,
	PRICE_MODEL_IS_SECTION_DISCOUNT_RULE_SEPARATE_FRAGMENT,
	PRICE_MODEL_NUMERICAL_NULL_DISPLAY_FRAGMENT,
	PRICE_MODEL_PRICE_ROUND_UP_FRAGMENT,
	PRICE_MODEL_SHOW_COMMITMENT_INPUT_FRAGMENT,
	PRICE_MODEL_SHOW_CROSSED_OUT_PRICES_FRAGMENT,
} from "@graphQl/fragments/priceModelDesignAndSetting";

import { PUBLIC_PACK_FRAGMENT } from "@graphQl/fragments/pack";
import { ERROR_FRAGMENT } from "@graphQl/fragments/error";
import { CATEGORY_PUBLIC_PRICE_MODEL_FRAGMENT } from "@graphQl/fragments/categoryPriceModel";
import {
	DISCOUNT_RULE_FOR_PUBLIC_PRICE_MODEL_FRAGMENT,
	VARIABLE_TIME_DISCOUNT_RULE_FRAGMENT,
} from "./discountRuleFragment";
import {
	CATEGORY_PACKAGE_BUILDER_FRAGMENT,
	FULL_CATEGORY_FRAGMENT,
} from "@graphQl/fragments/categoryPackageBuilderFragment";
import { PRICE_MODEL_DESIGN_AND_SETTING_FRAGMENT } from "./priceModelDesignAndSetting";
import { PUBLIC_PRICE_MODEL_FEATURE_FRAGMENT } from "@graphQl/fragments/priceModelFeature";

export const PRICE_MODEL_SHOW_ERRORS_FRAGMENT = gql`
	fragment PriceModelShowErrorsFragment on PriceModel {
		showErrors @client
	}
`;

export const PRICE_MODEL_DESCRIPTION_FRAGMENT = gql`
	fragment PriceModelDescriptionFragment on PriceModel {
		id
		description
	}
`;

export const PRICE_MODEL_IS_FAVORITE_FRAGMENT = gql`
	fragment PriceModelIsFavoriteFragment on PriceModel {
		id
		isFavorite
	}
`;

export const PRICE_MODEL_CREATED_AT_FRAGMENT = gql`
	fragment PriceModelCreatedAtFragment on PriceModel {
		id
		createdAt
	}
`;

export const PRICE_MODEL_CREATED_BY_FRAGMENT = gql`
	fragment PriceModelcreatedByFragment on PriceModel {
		id
		createdById
	}
`;

export const PRICE_MODEL_UPDATED_AT_FRAGMENT = gql`
	fragment PriceModelUpdatedAtFragment on PriceModel {
		id
		updatedAt
	}
`;

export const PRICE_MODEL_UPDATED_BY_FRAGMENT = gql`
	fragment PriceModelUpdatedByFragment on PriceModel {
		id
		updatedById
	}
`;

export const PRICE_MODEL_NUMBER_OF_PACKS_FRAGMENT = gql`
	fragment PriceModelNbPacksFragment on PriceModel {
		id
		nbPacks
	}
`;

export const PRICE_MODEL_FOR_PACK_UPDATE_FRAGMENT = gql`
	fragment PriceModelForPackUpdate on PriceModel {
		id
		packs {
			id
		}
	}
`;

export const PRICE_MODEL_NUMBER_OF_FEATURES_FRAGMENT = gql`
	fragment PriceModelNbFeaturesFragment on PriceModel {
		id
		nbFeatures
	}
`;

export const PRICE_MODEL_NUMBER_OF_POSSIBLE_PACKS_FRAGMENT = gql`
	fragment PriceModelNbPossiblePacksFragment on PriceModel {
		id
		nbPossiblePacks
	}
`;

export const PRICE_MODEL_NUMBER_OF_VOLUME_REFERENCE_METRIC_DISCOUNT_FRAGMENT = gql`
	fragment PriceModelnbVolumeOnReferenceMetricDiscountFragment on PriceModel {
		id
		nbVolumeOnReferenceMetricDiscount
	}
`;

export const PRICE_MODEL_NUMBER_OF_COMMITMENT_DISCOUNT_FRAGMENT = gql`
	fragment PriceModelnbCommitmentDiscountFragment on PriceModel {
		id
		nbCommitmentDiscount
	}
`;

export const PRICE_MODEL_NUMBER_OF_PAYMENT_TERM_DISCOUNT_FRAGMENT = gql`
	fragment PriceModelNbPaymentTermDiscountFragment on PriceModel {
		id
		nbPaymentTermDiscount
	}
`;

export const PRICE_MODEL_MIN_ENGAGEMENT_FROM_DISCOUNT_RULE_FRAGMENT = gql`
	fragment PriceModelMinEngagementFromDiscountRuleFragment on PriceModel {
		id
		minEngagementFromDiscountRule
	}
`;

export const PRICE_MODEL_NAME_FRAGMENT = gql`
	fragment PriceModelNameFragment on PriceModel {
		id
		name
	}
`;

export const ONLY_PRICE_MODEL_ERRORS_FRAGMENT = gql`
	${ERROR_FRAGMENT}
	fragment OnlyPriceModelErrorsFragment on PriceModel {
		id
		errors {
			...ErrorFragment
		}
	}
`;

export const PRICE_MODEL_ERRORS_FRAGMENT = gql`
	${ERROR_FRAGMENT}
	fragment PriceModelErrorsFragment on PriceModel {
		id
		errors {
			...ErrorFragment
		}
		packs {
			errors {
				...ErrorFragment
			}
		}
	}
`;

export const PRICE_MODEL_LANGUAGE_FRAGMENT = gql`
	fragment PriceModelLanguageFragment on PriceModel {
		id
		language
	}
`;
export const PRICE_MODEL_CURRENCY_FRAGMENT = gql`
	fragment PriceModelCurrencyFragment on PriceModel {
		id
		currency
	}
`;

export const PRICE_MODEL_ENABLE_PRICE_CONVERSION_FRAGMENT = gql`
	fragment PriceModelEnablePriceConversionFragment on PriceModel {
		id
		enablePriceConversion
	}
`;

export const PRICE_MODEL_NUMBER_OF_SPECIAL_DISCOUNTS_FRAGMENT = gql`
	fragment PriceModelNumberOfSpecialDiscountsFragment on PriceModel {
		id
		nbSpecialDiscount
	}
`;

export const PRICE_MODEL_DEFAULT_SELECTED_PACK_FRAGMENT = gql`
	fragment PriceModelDefaultSelectedPackFragment on PriceModel {
		id
		defaultSelectedPack
	}
`;
export const PRICE_MODEL_SHOW_PRICE_POINTS_SECTION_FRAGMENT = gql`
	fragment PriceModelShowPricePointsSectionFragment on PriceModel {
		showPricePointsSection @client
	}
`;

export const PRICE_MODEL_VOLUME_REFERENCE_FRAGMENT = gql`
	fragment PriceModelVolumeReferenceFragment on PriceModel {
		id
		volumeReference
	}
`;

export const PRICE_MODEL_PRIMARY_COLORS_FRAGMENT = gql`
	fragment PriceModelPrimaryColorsFragment on PriceModelDesignAndSetting {
		id
		backgroundPrimaryColor
		priceModelId
	}
`;

export const PRICE_MODEL_PACK_HIGHLIGHTED_FRAGMENT = gql`
	fragment PriceModelPackHighlightedFragment on PriceModel {
		id
		packHighlighted
	}
`;

export const PRICE_MODEL_DISPLAYED_VOLUME_DEFAULT_FRAGMENT = gql`
	fragment PriceModelDisplayedVolumeDefaultFragment on PriceModel {
		id
		displayedVolumeInputDefault
	}
`;

export const PRICE_MODEL_PACK_REFERENCE_FRAGMENT = gql`
	fragment PriceModelPackReferenceFragment on PriceModel {
		id
		packReference
	}
`;

export const PRICE_MODEL_TIME_METRIC_FRAGMENT = gql`
	fragment PriceModelTimeMetricFragment on PriceModel {
		id
		timeMetric
	}
`;

export const PRICE_MODEL_PRICE_METRIC_FRAGMENT = gql`
	fragment PriceModelPriceMetricFragment on PriceModel {
		id
		priceMetric
		uuid
	}
`;

export const PRICE_MODEL_WORKSPACE_ID_FRAGMENT = gql`
	fragment PriceModelWorkspaceIdFragment on PriceModel {
		id
		workspaceId
	}
`;

export const PRICE_MODEL_COMMITMENT_INPUT_SECTION_ID_FRAGMENT = gql`
	fragment PriceModelCommitmentInputSectionIdFragment on PriceModel {
		id
		commitmentInputSectionId
	}
`;

export const PRICE_MODEL_VOLUME_INPUT_SECTION_ID_FRAGMENT = gql`
	fragment PriceModelVolumeInputSectionIdFragment on PriceModel {
		id
		volumeInputSectionId
	}
`;

export const PRICE_MODEL_NO_VOLUME_DISCOUNT_RULE_FRAGMENT = gql`
	fragment PriceModelNoVolumeDiscountRuleFragment on PriceModel {
		id
		noVolumeDiscountRule
	}
`;

export const PRICE_MODEL_DISCOUNT_RULE_SECTIONS_FRAGMENT = gql`
	fragment PriceModelDiscountRuleSectionsFragment on PriceModel {
		id
		discountRuleSections {
			id
			uuid
			priceModelId
			title
			type
			discountRules {
				id
				uuid
				priceModelId
				type
				index
				name
				discountRuleSectionId
			}
		}
	}
`;

export const PRICE_MODEL_TIME_AND_PRICE_METRICS_FRAGMENT = gql`
	${PRICE_MODEL_TIME_METRIC_FRAGMENT}
	${PRICE_MODEL_PRICE_METRIC_FRAGMENT}
	fragment PriceModelTimeAndPriceMetricsFragment on PriceModel {
		...PriceModelTimeMetricFragment
		...PriceModelPriceMetricFragment
	}
`;

export const PRICE_MODEL_PACKS_FRAGMENT = gql`
	fragment PriceModelPacksFragment on PriceModel {
		id
		packs {
			id
		}
	}
`;

export const PRICE_MODEL_VARIABLE_TIME_DISCOUNT_FRAGMENT = gql`
	${PRICE_MODEL_TIME_METRIC_FRAGMENT}
	${VARIABLE_TIME_DISCOUNT_RULE_FRAGMENT}
	fragment PriceModelVariableTimeDiscountFragment on PriceModel {
		id
		...PriceModelTimeMetricFragment
		discountRule {
			id
			...VariableTimeDiscountRuleFragment
		}
	}
`;

export const PRICE_MODEL_MIN_ENGAGEMENT_FRAGMENT = gql`
	fragment PriceModelMinEngagementFragment on PriceModel {
		id
		minEngagement
	}
`;

export const PRICE_MODEL_MIN_ENGAGEMENT_AND_DISPLAYED_COMMITMENT_DEFAULT_FRAGMENT = gql`
	${PRICE_MODEL_DISPLAYED_COMMITMENT_DEFAULT_FRAGMENT}
	${PRICE_MODEL_MIN_ENGAGEMENT_FRAGMENT}
	fragment PriceModelMinEngagementAndDisplayedCommitmentDefaultFragment on PriceModel {
		...PriceModelDisplayedCommitmentDefaultFragment
		...PriceModelMinEngagementFragment
	}
`;

export const PRICE_MODEL_METRICS_FRAGMENT = gql`
	${PRICE_MODEL_PRICE_METRIC_FRAGMENT}
	${PRICE_MODEL_VOLUME_REFERENCE_FRAGMENT}
	${PRICE_MODEL_TIME_METRIC_FRAGMENT}
	fragment PriceModelMetricsFragment on PriceModel {
		...PriceModelPriceMetricFragment
		...PriceModelVolumeReferenceFragment
		...PriceModelTimeMetricFragment
	}
`;

export const PRICE_MODEL_DEFAULT_METRICS_FRAGMENT = gql`
	${PRICE_MODEL_PRICE_METRIC_FRAGMENT}
	${PRICE_MODEL_PRIMARY_METRICS_FRAGMENT}
	${PRICE_MODEL_SECONDARY_METRICS_FRAGMENT}
	${PRICE_MODEL_PRIMARY_TIME_METRIC_FRAGMENT}
	${PRICE_MODEL_SECONDARY_TIME_METRIC_FRAGMENT}
	${PRICE_MODEL_ENABLE_SECONDARY_TIME_METRIC_FRAGMENT}
	${PRICE_MODEL_TIME_METRIC_FRAGMENT}
	fragment PriceModelDefaultMetricsFragment on PriceModel {
		...PriceModelPriceMetricFragment
		...PriceModelPrimaryMetricsFragment
		...PriceModelSecondaryMetricsFragment
		...PriceModelPrimaryTimeMetricFragment
		...PriceModelSecondaryTimeMetricFragment
		...PriceModelEnableSecondaryTimeMetricFragment
		...PriceModelTimeMetricFragment
	}
`;

export const PRICE_MODEL_IS_TVA_FRAGMENT = gql`
	fragment PriceModelIsTvaFragment on PriceModel {
		id
		isTva
	}
`;

export const PRICE_MODEL_PRICE_METRIC_AND_VOLUME_REFERENCE_DATA_FRAGMENT = gql`
	${PRICE_MODEL_PRICE_METRIC_FRAGMENT}
	${PRICE_MODEL_VOLUME_REFERENCE_FRAGMENT}
	fragment PriceModelPriceMetricAndVolumeReferenceDataFragment on PriceModel {
		...PriceModelPriceMetricFragment
		...PriceModelVolumeReferenceFragment
	}
`;

export const PRICE_MODEL_METRICS_MIN_ENGAGEMENT_AND_VOLUME_REFERENCE_DATA_FRAGMENT = gql`
	${PRICE_MODEL_METRICS_FRAGMENT}
	${PRICE_MODEL_MIN_ENGAGEMENT_FRAGMENT}
	fragment PriceModelMetricsMinEngagementAndVolumeReferenceDataFragment on PriceModel {
		...PriceModelMetricsFragment
		...PriceModelMinEngagementFragment
	}
`;

export const PRICE_MODEL_SHOW_BOOSTER_OPTIONS_PRICE_FRAGMENT = gql`
	fragment PriceModelShowBoosterOptionsPriceFragment on PriceModelDesignAndSetting {
		id
		showBoosterOptionsPrice
		priceModelId
	}
`;

export const PRICE_MODEL_FAKE_CATEGORIE_WEIGHT_FRAGMENT = gql`
	fragment PriceModelFakeCategoryPriceModelWeightFragment on PriceModel {
		id
		fakeCategoryPriceModelWeight
	}
`;

export const PRICE_MODEL_EXPIRATION_DATE_FRAGMENT = gql`
	fragment PriceModelExpirationDateFragment on PriceModel {
		id
		expirationDate
	}
`;

export const PRICE_MODEL_CONFIGURATION_OPTIONS_FRAGMENT = gql`
	${PRICE_MODEL_NUMBER_OF_VOLUME_REFERENCE_METRIC_DISCOUNT_FRAGMENT}
	${PRICE_MODEL_NUMBER_OF_COMMITMENT_DISCOUNT_FRAGMENT}
	${PRICE_MODEL_NUMBER_OF_PAYMENT_TERM_DISCOUNT_FRAGMENT}
	fragment PriceModelConfigurationOptionsFragment on PriceModel {
		...PriceModelnbVolumeOnReferenceMetricDiscountFragment
		...PriceModelnbCommitmentDiscountFragment
		...PriceModelNbPaymentTermDiscountFragment
	}
`;

export const FULL_PRICE_MODEL_FRAGMENT = gql`
	${PRICE_MODEL_CREATED_BY_FRAGMENT}
	${PRICE_MODEL_NAME_FRAGMENT}
	fragment FullPriceModelFragment on PriceModel {
		id
		uuid
		...PriceModelcreatedByFragment
		...PriceModelNameFragment
		discountRule {
			id
		}
	}
`;

export const PRICE_MODEL_OVERVIEW_FRAGMENT = gql`
	${PRICE_MODEL_NAME_FRAGMENT}
	${ONLY_PRICE_MODEL_ERRORS_FRAGMENT}
	${PRICE_MODEL_IS_FAVORITE_FRAGMENT}
	fragment PriceModelOverviewFragment on PriceModel {
		id
		...PriceModelNameFragment
		...OnlyPriceModelErrorsFragment
		...PriceModelIsFavoriteFragment
		workspaceId
		description
		createdAt
		updatedAt
		createdById
		updatedById
		nbPacks
		nbFeatures
		nbPossiblePacks
		nbVolumeOnReferenceMetricDiscount
		nbCommitmentDiscount
		nbPaymentTermDiscount
		nbSpecialDiscount
		language
	}
`;

export const PRICE_MODEL_FRAGMENT = gql`
	${PRICE_MODEL_METRICS_FRAGMENT}
	${PRICE_MODEL_PRICE_METRIC_FRAGMENT}
	${PRICE_MODEL_PACK_REFERENCE_FRAGMENT}
	${PRICE_MODEL_VOLUME_REFERENCE_FRAGMENT}
	${PRICE_MODEL_IS_TVA_FRAGMENT}
	${PRICE_MODEL_MIN_ENGAGEMENT_FRAGMENT}
	${PRICE_MODEL_FAKE_CATEGORIE_WEIGHT_FRAGMENT}
	${PRICE_MODEL_LANGUAGE_FRAGMENT}
	${PRICE_MODEL_NAME_FRAGMENT}
	${PRICE_MODEL_CREATED_BY_FRAGMENT}
	${PRICE_MODEL_UPDATED_BY_FRAGMENT}
	${CATEGORY_PACKAGE_BUILDER_FRAGMENT}
	fragment PriceModelFragment on PriceModel {
		id
		updatedAt
		createdAt
		...PriceModelMetricsFragment
		...PriceModelPackReferenceFragment
		...PriceModelIsTvaFragment
		...PriceModelMinEngagementFragment
		...PriceModelFakeCategoryPriceModelWeightFragment
		...PriceModelLanguageFragment
		...PriceModelNameFragment
		...PriceModelcreatedByFragment
		...PriceModelUpdatedByFragment
		currency
		selectedCategories {
			...CategoryPackageBuilderFragment
		}
	}
`;

export const CREATE_PRICE_MODEL_FRAGMENT = gql`
	${PRICE_MODEL_OVERVIEW_FRAGMENT}
	${PRICE_MODEL_METRICS_FRAGMENT}
	${CATEGORY_PACKAGE_BUILDER_FRAGMENT}
	fragment CreatePriceModelFragment on PriceModel {
		...PriceModelOverviewFragment
		...PriceModelMetricsFragment
		priceModelCombinations {
			id
			name
			token
			tokenId
			enablePassword
			shouldLinkVolumes
			tokenObj {
				id
			}
			priceModelLinkCombination {
				id
			}
		}
		selectedCategories {
			...CategoryPackageBuilderFragment
		}
	}
`;

export const PUBLIC_PRICE_MODEL_FOR_COMBINATION_FRAGMENT = gql`
	${PRICE_MODEL_NAME_FRAGMENT}
	${PRICE_MODEL_DESCRIPTION_FRAGMENT}
	${PRICE_MODEL_PACK_REFERENCE_FRAGMENT}
	${PRICE_MODEL_TIME_METRIC_FRAGMENT}
	${PRICE_MODEL_PRICE_METRIC_FRAGMENT}
	${PRICE_MODEL_PACK_HIGHLIGHTED_FRAGMENT}
	${PRICE_MODEL_DEFAULT_SELECTED_PACK_FRAGMENT}
	${PRICE_MODEL_VOLUME_REFERENCE_FRAGMENT}
	${PRICE_MODEL_IS_TVA_FRAGMENT}
	${PRICE_MODEL_MIN_ENGAGEMENT_FRAGMENT}
	${PRICE_MODEL_LANGUAGE_FRAGMENT}
	${PRICE_COMMUNICATION_DISPLAYED_GENERAL_TITLE_FRAGMENT}
	${PRICE_MODEL_SHOW_COMMITMENT_INPUT_FRAGMENT}
	${PRICE_MODEL_DISPLAYED_VOLUME_DEFAULT_FRAGMENT}
	${PRICE_MODEL_PRIMARY_TIME_METRIC_FRAGMENT}
	${PRICE_MODEL_SECONDARY_TIME_METRIC_FRAGMENT}
	${PRICE_MODEL_ENABLE_SECONDARY_TIME_METRIC_FRAGMENT}
	${PRICE_MODEL_DISPLAYED_COMMITMENT_DEFAULT_FRAGMENT}
	${PRICE_COMMUNICATION_FLAG_FRAGMENT}
	${PRICE_MODEL_DS_SHOW_SAVINGS_FRAGMENT}
	${PRICE_MODEL_PRIMARY_COLORS_FRAGMENT}
	${PRICE_MODEL_EXPIRATION_DATE_FRAGMENT}
	${PUBLIC_PACK_FRAGMENT}
	${PUBLIC_PRICE_MODEL_FEATURE_FRAGMENT}
	${CATEGORY_PUBLIC_PRICE_MODEL_FRAGMENT}
	${DISCOUNT_RULE_FOR_PUBLIC_PRICE_MODEL_FRAGMENT}
	${PRICE_MODEL_SHOW_BOOSTER_OPTIONS_PRICE_FRAGMENT}
	${PRICE_MODEL_PRIMARY_METRICS_FRAGMENT}
	${PRICE_MODEL_SECONDARY_METRICS_FRAGMENT}
	${PRICE_MODEL_PRICE_ROUND_UP_FRAGMENT}
	${PRICE_MODEL_DESIGN_AND_SETTING_IS_ROUND_UP_ABOVE_THOUSAND_FRAGMENT}
	${PRICE_MODEL_SHOW_CROSSED_OUT_PRICES_FRAGMENT}
	${PRICE_MODEL_DESIGN_AND_SETTING_FRAGMENT}
	${PRICE_MODEL_DESIGN_AND_SETTING_ADDON_SECTION_NAME_BEFORE_FRAGMENT}
	${PRICE_MODEL_DESIGN_AND_SETTING_EXTRA_SECTION_NAME_BEFORE_FRAGMENT}
	${PRICE_MODEL_DESIGN_AND_SETTING_ADDON_SECTION_NAME_AFTER_FRAGMENT}
	${PRICE_MODEL_DESIGN_AND_SETTING_EXTRA_SECTION_NAME_AFTER_FRAGMENT}
	${PRICE_MODEL_DESIGN_AND_SETTING_ADDON_OPENED_BY_DEFAULT_AFTER_FRAGMENT}
	${PRICE_MODEL_DESIGN_AND_SETTING_EXTRA_OPENED_BY_DEFAULT_AFTER_FRAGMENT}
	${PRICE_MODEL_DESIGN_AND_SETTING_ADDON_OPENED_BY_DEFAULT_BEFORE_FRAGMENT}
	${PRICE_MODEL_DESIGN_AND_SETTING_EXTRA_OPENED_BY_DEFAULT_BEFORE_FRAGMENT}
	${PRICE_MODEL_ARE_FEATURE_DESCRIPTIONS_WRAPPED_FRAGMENT}
	${PRICE_MODEL_CORNER_RADIUS_FRAGMENT}
	${PRICE_MODEL_COMMITMENT_INPUT_SECTION_ID_FRAGMENT}
	${PRICE_MODEL_VOLUME_INPUT_SECTION_ID_FRAGMENT}
	${PRICE_MODEL_IS_SECTION_DISCOUNT_RULE_SEPARATE_FRAGMENT}
	${PRICE_MODEL_DISCOUNT_RULE_SECTIONS_FRAGMENT}
	${PRICE_MODEL_NO_VOLUME_DISCOUNT_RULE_FRAGMENT}
	${PRICE_MODEL_DISPLAYED_VAT_DEFAULT_FRAGMENT}
	${PRICE_MODEL_NUMERICAL_NULL_DISPLAY_FRAGMENT}
	${PRICE_MODEL_DESCRIPTIVE_NULL_DISPLAY_FRAGMENT}
	fragment PublicPriceModelForCombinationFragment on PriceModel {
		...PriceModelNameFragment
		...PriceModelDescriptionFragment
		...PriceModelPackReferenceFragment
		...PriceModelTimeMetricFragment
		...PriceModelPriceMetricFragment
		...PriceModelPackHighlightedFragment
		...PriceModelDefaultSelectedPackFragment
		...PriceModelVolumeReferenceFragment
		...PriceModelIsTvaFragment
		...PriceModelMinEngagementFragment
		...PriceModelLanguageFragment
		...PriceCommunicationDisplayedGeneralTitleFragment
		...PriceModelDisplayedVolumeDefaultFragment
		...PriceModelPrimaryTimeMetricFragment
		...PriceModelSecondaryTimeMetricFragment
		...PriceModelDisplayedCommitmentDefaultFragment
		...PriceModelExpirationDateFragment
		...PriceModelPrimaryMetricsFragment
		...PriceModelSecondaryMetricsFragment
		...PriceModelEnableSecondaryTimeMetricFragment
		...PriceModelCommitmentInputSectionIdFragment
		...PriceModelVolumeInputSectionIdFragment
		...PriceModelDiscountRuleSectionsFragment
		...PriceModelNoVolumeDiscountRuleFragment
		packs {
			...PublicPackPriceModelFragment
		}
		priceModelFeatures {
			...PublicPriceModelFeatureFragment
		}
		categories {
			...CategoryPublicPriceModelFragment
		}
		discountRule {
			...DiscountRuleForPublicPriceModelFragment
		}
		designAndSettings {
			...PriceModelPriceRoundUpFragment
			...PriceModelShowCrossedOutPricesFragment
			...PriceModelDesignAndSettingFragment
			...PriceModelShowCommitmentInputFragment
			...PriceModelDesignAndSettingAddOnSectionNameBeforeFragment
			...PriceModelDesignAndSettingExtraSectionNameBeforeFragment
			...PriceModelDesignAndSettingAddOnSectionNameAfterFragment
			...PriceModelDesignAndSettingExtraSectionNameAfterFragment
			...PriceModelDesignAndSettingAddonOpenedByDefaultBeforeFragment
			...PriceModelDesignAndSettingExtraOpenedByDefaultBeforeFragment
			...PriceModelDesignAndSettingAddonOpenedByDefaultAfterFragment
			...PriceModelDesignAndSettingExtraOpenedByDefaultAfterFragment
			...PriceModelDesignAndSettingIsRoundUpAboveThousandFragment
			...PriceModelAreFeatureDescriptionsWrappedFragment
			...PriceModelCornerRadiusFragment
			...PriceCommunicationFlagFragment
			...PriceModelPrimaryColorsFragment
			...PriceModelShowBoosterOptionsPriceFragment
			...PriceModelShowSavingsFragment
			...PriceModelIsSectionDiscountRuleSeparateFragment
			...PriceModelDisplayedVatDefaultFragment
			...PriceModelNumericalNullDisplayFragment
			...PriceModelDescriptiveNullDisplayFragment
		}
	}
`;

export const PRICE_MODEL_CONFIRMATION_FRAGMENT = gql`
	${PRICE_MODEL_WORKSPACE_ID_FRAGMENT}
	fragment PriceModelConfirmationFragment on PriceModel {
		...PriceModelWorkspaceIdFragment
	}
`;

export const PRICE_MODELS_WITH_CATEGORY_FRAGMENT = gql`
	${PRICE_MODEL_FRAGMENT}
	${FULL_CATEGORY_FRAGMENT}
	fragment PriceModelsWithCategoryFragment on PriceModel {
		...PriceModelFragment
		selectedCategories {
			...FullCategoryFragment
		}
	}
`;
