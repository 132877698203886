import { gql } from "@apollo/client";
import { PRICE_MODEL_METRIC_FRAGMENT } from "./priceModelMetric";
import { PRICE_MODEL_TIME_METRIC_DATA_FRAGMENT } from "./priceModelTimeMetric";

export const PRICE_COMMUNICATION_DISPLAYED_GENERAL_TITLE_FRAGMENT = gql`
	fragment PriceCommunicationDisplayedGeneralTitleFragment on PriceModel {
		id
		displayedGeneralTitle
	}
`;

export const PRICE_COMMUNICATION_LANGUAGE_FRAGMENT = gql`
	fragment PriceCommunicationLanguageFragment on PriceModel {
		id
		language
	}
`;

export const PRICE_COMMUNICATION_FLAG_FRAGMENT = gql`
	fragment PriceCommunicationFlagFragment on PriceModelDesignAndSetting {
		id
		flag
		priceModelId
	}
`;

export const LANGUAGE_OPTIONS_FOR_PRICE_MODELS_FRAGMENT = gql`
	${PRICE_COMMUNICATION_LANGUAGE_FRAGMENT}
	fragment LanguageOptionsPriceCommunicationFragment on PriceModel {
		id
		...PriceCommunicationLanguageFragment
	}
`;

export const PRICE_MODEL_PRIMARY_TIME_METRIC_FRAGMENT = gql`
	${PRICE_MODEL_TIME_METRIC_DATA_FRAGMENT}
	fragment PriceModelPrimaryTimeMetricFragment on PriceModel {
		id
		primaryTimeMetric {
			...PriceModelTimeMetricDataFragment
		}
	}
`;

export const PRICE_MODEL_SECONDARY_TIME_METRIC_FRAGMENT = gql`
	${PRICE_MODEL_TIME_METRIC_DATA_FRAGMENT}
	fragment PriceModelSecondaryTimeMetricFragment on PriceModel {
		id
		secondaryTimeMetric {
			...PriceModelTimeMetricDataFragment
		}
	}
`;

export const PRICE_MODEL_ENABLE_SECONDARY_TIME_METRIC_FRAGMENT = gql`
	fragment PriceModelEnableSecondaryTimeMetricFragment on PriceModel {
		id
		enableSecondaryPriceDisplay
	}
`;

export const PRICE_MODEL_PRIMARY_METRICS_FRAGMENT = gql`
	${PRICE_MODEL_METRIC_FRAGMENT}
	fragment PriceModelPrimaryMetricsFragment on PriceModel {
		id
		primaryMetrics {
			...PriceModelMetric
		}
	}
`;

export const PRICE_MODEL_SECONDARY_METRICS_FRAGMENT = gql`
	${PRICE_MODEL_METRIC_FRAGMENT}
	fragment PriceModelSecondaryMetricsFragment on PriceModel {
		id
		secondaryMetrics {
			...PriceModelMetric
		}
	}
`;

export const PRICE_MODEL_DISPLAYED_COMMITMENT_DEFAULT_FRAGMENT = gql`
	fragment PriceModelDisplayedCommitmentDefaultFragment on PriceModel {
		id
		displayedCommitmentInputDefault
	}
`;
