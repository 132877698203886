import { gql } from "@apollo/client";
import { PACK_FRAGMENT } from "@graphQl/fragments/pack";
import {
	LEVEL_DATA_FRAGMENT,
	PUBLIC_LEVEL_DATA_FOR_LEVEL_FRAGMENT,
} from "@graphQl/fragments/levelData";
import {
	PUBLIC_SELECT_OPTION_FRAGMENT,
	SELECT_OPTION_FRAGMENT,
} from "@graphQl/fragments/selectOption";
import {
	NUMERICAL_RANGE_FRAGMENT,
	PUBLIC_NUMERICAL_RANGE_FRAGMENT,
} from "@graphQl/fragments/numericalRange";
import {
	PUBLIC_SWITCH_OPTION_FRAGMENT,
	SWITCH_OPTION_FRAGMENT,
} from "@graphQl/fragments/switchOption";
import { ERROR_FRAGMENT } from "./error";

export const LEVEL_PACK_ID_AND_FEATURE_ID_FRAGMENT = gql`
	fragment LevelPackIdAndFeatureIdFragment on Level {
		id
		packId
		featureId
	}
`;

export const LEVEL_PACK_FRAGMENT = gql`
	${LEVEL_PACK_ID_AND_FEATURE_ID_FRAGMENT}
	${PACK_FRAGMENT}
	fragment LevelPackFragment on Level {
		...LevelPackIdAndFeatureIdFragment
		pack {
			...PackPriceModelFragment
		}
	}
`;

export const LEVEL_IS_LINKED_FRAGMENT = gql`
	${LEVEL_PACK_ID_AND_FEATURE_ID_FRAGMENT}
	fragment LevelIsLinkedFragment on Level {
		...LevelPackIdAndFeatureIdFragment
		isLinked
	}
`;

export const LEVEL_DEFAULT_BOOSTER_ID_FRAGMENT = gql`
	${LEVEL_PACK_ID_AND_FEATURE_ID_FRAGMENT}
	fragment LevelDefaultBoosterIdFragment on Level {
		...LevelPackIdAndFeatureIdFragment
		defaultBoosterId
	}
`;

export const LEVEL_VALUE_ID_FRAGMENT = gql`
	${LEVEL_PACK_ID_AND_FEATURE_ID_FRAGMENT}
	fragment LevelValueIdFragment on Level {
		...LevelPackIdAndFeatureIdFragment
		valueId
	}
`;

export const LEVEL_VALUE_FRAGMENT = gql`
	${LEVEL_PACK_ID_AND_FEATURE_ID_FRAGMENT}
	${SELECT_OPTION_FRAGMENT}
	${NUMERICAL_RANGE_FRAGMENT}
	${SWITCH_OPTION_FRAGMENT}
	fragment LevelValueFragment on Level {
		...LevelPackIdAndFeatureIdFragment
		value {
			... on SelectOption {
				...SelectOptionFragment
			}
			... on NumericalRange {
				...NumericalRangeFragment
			}
			... on SwitchOption {
				...SwitchOptionFragment
			}
		}
	}
`;

export const PUBLIC_LEVEL_VALUE_FRAGMENT = gql`
	${LEVEL_PACK_ID_AND_FEATURE_ID_FRAGMENT}
	${PUBLIC_SELECT_OPTION_FRAGMENT}
	${PUBLIC_NUMERICAL_RANGE_FRAGMENT}
	${PUBLIC_SWITCH_OPTION_FRAGMENT}
	fragment PublicLevelValueFragment on Level {
		...LevelPackIdAndFeatureIdFragment
		value {
			... on SelectOption {
				...PublicSelectOptionFragment
			}
			... on NumericalRange {
				...PublicNumericalRangeFragment
			}
			... on SwitchOption {
				...PublicSwitchOptionFragment
			}
		}
	}
`;

export const PUBLIC_SMALL_LEVEL_VALUE_FRAGMENT = gql`
	${LEVEL_PACK_ID_AND_FEATURE_ID_FRAGMENT}
	fragment PublicSmallLevelValueFragment on Level {
		...LevelPackIdAndFeatureIdFragment
		value {
			... on SelectOption {
				id
			}
			... on NumericalRange {
				id
			}
			... on SwitchOption {
				id
			}
		}
	}
`;

export const LEVEL_LEVEL_DATA_ID_FRAGMENT = gql`
	${LEVEL_PACK_ID_AND_FEATURE_ID_FRAGMENT}
	fragment LevelLevelDataIdFragment on Level {
		...LevelPackIdAndFeatureIdFragment
		levelDataId
	}
`;

export const LEVEL_LEVEL_DATA_VALUE_AND_ID_FRAGMENT = gql`
	${LEVEL_PACK_ID_AND_FEATURE_ID_FRAGMENT}
	${ERROR_FRAGMENT}
	fragment LevelLevelDataValueAndIdFragment on Level {
		...LevelPackIdAndFeatureIdFragment
		levelDataValue
		levelDataId
		defaultBoosterId
		defaultBoosterValue
		errors {
			...ErrorFragment
		}
	}
`;

export const LEVEL_LEVEL_DATA_VALUE_FRAGMENT = gql`
	${LEVEL_PACK_ID_AND_FEATURE_ID_FRAGMENT}
	fragment LevelLevelDataValueFragment on Level {
		...LevelPackIdAndFeatureIdFragment
		levelDataValue
	}
`;

export const LEVEL_DEFAULT_BOOSTER_VALUE_FRAGMENT = gql`
	${LEVEL_PACK_ID_AND_FEATURE_ID_FRAGMENT}
	${ERROR_FRAGMENT}
	fragment LevelDefaultBoosterValueFragment on Level {
		...LevelPackIdAndFeatureIdFragment
		defaultBoosterValue
		defaultBoosterId
		isLinked
		errors {
			...ErrorFragment
		}
	}
`;

export const LEVEL_IS_LOCKED_FRAGMENT = gql`
	${LEVEL_PACK_ID_AND_FEATURE_ID_FRAGMENT}
	fragment LevelIsLockedFragment on Level {
		...LevelPackIdAndFeatureIdFragment
		isLocked
	}
`;

export const LEVEL_FEATURE_GIFT_FRAGMENT = gql`
	${LEVEL_PACK_ID_AND_FEATURE_ID_FRAGMENT}
	fragment LevelFeatureGiftFragment on Level {
		...LevelPackIdAndFeatureIdFragment
		featureGift {
			id
			isGiven
			canBeGiven
		}
	}
`;

export const LEVEL_LEVEL_DATA_FRAGMENT = gql`
	${LEVEL_PACK_ID_AND_FEATURE_ID_FRAGMENT}
	${LEVEL_DATA_FRAGMENT}
	fragment LevelLevelDataFragment on Level {
		...LevelPackIdAndFeatureIdFragment
		levelData {
			...LevelDataFragment
		}
	}
`;

export const LEVEL_FRAGMENT = gql`
	${LEVEL_PACK_ID_AND_FEATURE_ID_FRAGMENT}
	${LEVEL_PACK_FRAGMENT}
	${LEVEL_VALUE_ID_FRAGMENT}
	${LEVEL_VALUE_FRAGMENT}
	${LEVEL_LEVEL_DATA_ID_FRAGMENT}
	${LEVEL_LEVEL_DATA_FRAGMENT}
	${LEVEL_DEFAULT_BOOSTER_ID_FRAGMENT}
	${LEVEL_LEVEL_DATA_VALUE_AND_ID_FRAGMENT}
	${LEVEL_IS_LOCKED_FRAGMENT}
	${LEVEL_IS_LINKED_FRAGMENT}
	${LEVEL_FEATURE_GIFT_FRAGMENT}
	fragment LevelFragment on Level {
		...LevelPackIdAndFeatureIdFragment
		...LevelPackFragment
		...LevelValueIdFragment
		...LevelValueFragment
		...LevelLevelDataIdFragment
		...LevelLevelDataFragment
		...LevelDefaultBoosterIdFragment
		...LevelLevelDataValueAndIdFragment
		...LevelIsLockedFragment
		...LevelIsLinkedFragment
		...LevelFeatureGiftFragment
		priceModelFeatureId
	}
`;

export const PUBLIC_SMALL_LEVEL_FRAGMENT = gql`
	${LEVEL_PACK_ID_AND_FEATURE_ID_FRAGMENT}
	${LEVEL_VALUE_ID_FRAGMENT}
	${PUBLIC_LEVEL_VALUE_FRAGMENT}
	${PUBLIC_SMALL_LEVEL_VALUE_FRAGMENT}
	${LEVEL_LEVEL_DATA_ID_FRAGMENT}
	${LEVEL_DEFAULT_BOOSTER_ID_FRAGMENT}
	${PUBLIC_LEVEL_DATA_FOR_LEVEL_FRAGMENT}
	${LEVEL_DEFAULT_BOOSTER_VALUE_FRAGMENT}
	${LEVEL_IS_LOCKED_FRAGMENT}
	${LEVEL_LEVEL_DATA_VALUE_FRAGMENT}
	${LEVEL_IS_LINKED_FRAGMENT}
	${LEVEL_FEATURE_GIFT_FRAGMENT}

	fragment PublicSmallLevelFragment on Level {
		levelData {
			...PublicLevelDataForLevelFragment
		}
		...LevelPackIdAndFeatureIdFragment
		...LevelValueIdFragment
		...PublicLevelValueFragment
		...PublicSmallLevelValueFragment
		...LevelLevelDataIdFragment
		...LevelDefaultBoosterIdFragment
		...LevelDefaultBoosterValueFragment
		...LevelIsLockedFragment
		...LevelLevelDataValueFragment
		...LevelIsLinkedFragment
		...LevelFeatureGiftFragment
	}
`;

export const LEVEL_LEVEL_DATA_ID_AND_IS_LINKED_FRAGMENT = gql`
	${LEVEL_LEVEL_DATA_ID_FRAGMENT}
	${LEVEL_IS_LINKED_FRAGMENT}
	fragment LevelLevelDataIdAndIsLinkedFragment on Level {
		...LevelLevelDataIdFragment
		...LevelIsLinkedFragment
	}
`;
