import { gql } from "@apollo/client";

export const ENGAGEMENT_RANGE_DISCOUNT_PACK_ID_FRAGMENT = gql`
	fragment EngagementRangeDiscountPackIdFragment on EngagementRangeDiscount {
		id
		packId
		engagementRangeId
	}
`;

export const ENGAGEMENT_RANGE_DISCOUNT_FRAGMENT = gql`
	fragment EngagementRangeDiscountFragment on EngagementRangeDiscount {
		id
		packId
		engagementRangeId
		discount
	}
`;

export const ENGAGEMENT_RANGE_DISCOUNT_AND_PRICE_FRAGMENT = gql`
	fragment EngagementRangeDiscountAndPriceFragment on EngagementRangeDiscount {
		id
		packId
		engagementRangeId
		discount
		price
	}
`;

export const ENGAGEMENT_RANGE_DISCOUNT_PACK_ID_ENGAGEMENT_RANGE_ID_AND_ENABLE_DISCOUNT_FRAGMENT = gql`
	fragment EngagementRangeDiscountPackIdEngagementRangeIdAndEnableDiscountFragment on EngagementRangeDiscount {
		id
		packId
		engagementRangeId
		enableDiscount
	}
`;

export const ENGAGEMENT_RANGE_DISCOUNT_PACK_ID_AND_DISCOUNT_FRAGMENT = gql`
	fragment EngagementRangeDiscountPackIdAndDiscountFragment on EngagementRangeDiscount {
		id
		packId
		discount
	}
`;

export const ENGAGEMENT_RANGE_DISCOUNT_ABSOLUTE_DISCOUNT_FRAGMENT = gql`
	${ENGAGEMENT_RANGE_DISCOUNT_PACK_ID_FRAGMENT}
	fragment EngagementRangeDiscountAbsoluteDiscountFragment on EngagementRangeDiscount {
		id
		absoluteDiscount
		...EngagementRangeDiscountPackIdFragment
	}
`;

export const ENGAGEMENT_RANGE_DISCOUNT_RANGE_DIFF_PREVIOUS_LINE_FRAGMENT = gql`
	${ENGAGEMENT_RANGE_DISCOUNT_PACK_ID_FRAGMENT}
	fragment EngagementRangeDiscountRangeDiffPreviousLineFragment on EngagementRangeDiscount {
		id
		diffPreviousRange
		...EngagementRangeDiscountPackIdFragment
	}
`;

export const ENGAGEMENT_RANGE_DISCOUNT_FULL_FRAGMENT = gql`
	fragment EngagementRangeDiscountFullFragment on EngagementRangeDiscount {
		id
		packId
		engagementRangeId
		discount
		warning
		price
		enableDiscount
		showEnableToggle
		absoluteDiscount
		diffPreviousRange
	}
`;
