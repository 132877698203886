import { gql } from "@apollo/client";
import { ENGAGEMENT_RANGE_FULL_FRAGMENT } from "@graphQl/fragments/engagementRangeFragment";

export const IS_LOCKED_ENGAGEMENT_DISCOUNT_FRAGMENT = gql`
	fragment IsLockedEngagementDiscountFragment on EngagementDiscount {
		id
		isLocked
	}
`;

export const ENGAGEMENT_DISCOUNT_ENGAGEMENT_RANGE_FRAGMENT = gql`
	${ENGAGEMENT_RANGE_FULL_FRAGMENT}
	${IS_LOCKED_ENGAGEMENT_DISCOUNT_FRAGMENT}
	fragment EngagementDiscountEngagementRangeFragment on EngagementDiscount {
		id
		uuid
		...IsLockedEngagementDiscountFragment
		engagementRange {
			...EngagementRangeFullFragment
		}
	}
`;
