import { gql } from "@apollo/client";

export const VOLUME_DISCOUNT_LINK_ID_FRAGMENT = gql`
	fragment VolumeDiscountLinkIdFragment on VolumeDiscountLink {
		id
	}
`;

export const VOLUME_DISCOUNT_LINK_UNIT_METRIC_FRAGMENT = gql`
	${VOLUME_DISCOUNT_LINK_ID_FRAGMENT}
	fragment VolumeDiscountLinkUnitMetricFragment on VolumeDiscountLink {
		...VolumeDiscountLinkIdFragment
		unitMetric
	}
`;

export const VOLUME_DISCOUNT_LINK_PLURAL_UNIT_METRIC_FRAGMENT = gql`
	${VOLUME_DISCOUNT_LINK_ID_FRAGMENT}
	fragment VolumeDiscountLinkPluralUnitMetricFragment on VolumeDiscountLink {
		...VolumeDiscountLinkIdFragment
		pluralUnitMetric
	}
`;

export const VOLUME_DISCOUNT_LINK_FRAGMENT = gql`
	${VOLUME_DISCOUNT_LINK_ID_FRAGMENT}
	${VOLUME_DISCOUNT_LINK_UNIT_METRIC_FRAGMENT}
	${VOLUME_DISCOUNT_LINK_PLURAL_UNIT_METRIC_FRAGMENT}
	fragment VolumeDiscountLinkFragment on VolumeDiscountLink {
		...VolumeDiscountLinkIdFragment
		...VolumeDiscountLinkUnitMetricFragment
		...VolumeDiscountLinkPluralUnitMetricFragment
	}
`;

export const VOLUME_DISCOUNT_LINK_VOLUME_DISCOUNTS_FRAGMENT = gql`
	${VOLUME_DISCOUNT_LINK_ID_FRAGMENT}
	${VOLUME_DISCOUNT_LINK_FRAGMENT}
	fragment VolumeDiscountLinkVolumeDiscountsFragment on VolumeDiscountLink {
		...VolumeDiscountLinkIdFragment
		...VolumeDiscountLinkFragment
		volumeDiscounts {
			id
			unitMetric
			pluralUnitMetric
		}
	}
`;
