import { gql } from "@apollo/client";
import { OFFER_VERSION_QUOTE_FRAGMENT } from "@graphQl/fragments/offerVersionQuote";
import { PUBLIC_PRICE_MODEL_COMBINATION_FRAGMENT } from "@graphQl/fragments/priceModelCombinationFragment";
import { OFFER_VERSION_RULE_FRAGMENT } from "@graphQl/fragments/offerVersionRule";

export const OFFER_VERSION_ID_FRAGMENT = gql`
	fragment OfferVersionIdFragment on OfferVersion {
		id
	}
`;

export const LAST_OFFER_VERSION_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	${OFFER_VERSION_ID_FRAGMENT}
	${OFFER_VERSION_QUOTE_FRAGMENT}
	${PUBLIC_PRICE_MODEL_COMBINATION_FRAGMENT}
	fragment LastOfferVersionPriceModelCombinationFragment on OfferVersion {
		...OfferVersionIdFragment
		offerVersionQuotes {
			...OfferVersionQuoteFragment
		}
		priceModelCombination {
			...PublicPriceModelCombinationFragment
		}
	}
`;

export const OFFER_VERSION_OFFER_VERSION_RULES_FRAGMENT = gql`
	${OFFER_VERSION_ID_FRAGMENT}
	${OFFER_VERSION_RULE_FRAGMENT}
	fragment OfferVersionOfferVersionRulesFragment on OfferVersion {
		...OfferVersionIdFragment
		offerVersionRules {
			...OfferVersionRuleFragment
		}
	}
`;

export const LAST_OFFER_VERSION_FRAGMENT = gql`
	${OFFER_VERSION_ID_FRAGMENT}
	${LAST_OFFER_VERSION_PRICE_MODEL_COMBINATION_FRAGMENT}
	${OFFER_VERSION_OFFER_VERSION_RULES_FRAGMENT}
	fragment LastOfferVersionFragment on OfferVersion {
		...OfferVersionIdFragment
		...LastOfferVersionPriceModelCombinationFragment
		...OfferVersionOfferVersionRulesFragment
	}
`;
