import { gql } from "@apollo/client";
import { USER_FRAGMENT } from "@graphQl/fragments/user";
import { TOKEN_FRAGMENT } from "@graphQl/fragments/token";
import { LAST_OFFER_VERSION_FRAGMENT } from "@graphQl/fragments/offerVersion";
import { OFFER_CONTACT_FRAGMENT } from "./offerContact";
import { ADDRESS_FRAGMENT } from "./address";
import { OFFER_STATUS_FRAGMENT } from "@graphQl/fragments/offerStatus";
import { OFFER_PROPERTIES_FOR_OFFER_FRAGMENT } from "./offerProperties";

export const OFFER_ID_FRAGMENT = gql`
	fragment OfferIdFragment on Offer {
		id
	}
`;

export const OFFER_NAME_FRAGMENT = gql`
	${OFFER_ID_FRAGMENT}
	fragment OfferNameFragment on Offer {
		...OfferIdFragment
		name
	}
`;

export const OFFER_COMPANY_NAME_FRAGMENT = gql`
	${OFFER_ID_FRAGMENT}
	fragment OfferCompanyNameFragment on Offer {
		...OfferIdFragment
		companyName
	}
`;

export const OFFER_OWNER_FRAGMENT = gql`
	${OFFER_ID_FRAGMENT}
	${USER_FRAGMENT}
	fragment OfferOwnerFragment on Offer {
		...OfferIdFragment
		owner {
			...UserFragment
		}
	}
`;

export const OFFER_TOKEN_FRAGMENT = gql`
	${OFFER_ID_FRAGMENT}
	${TOKEN_FRAGMENT}
	fragment OfferTokenFragment on Offer {
		...OfferIdFragment
		tokenObj {
			...TokenFragment
		}
	}
`;

export const OFFER_WORKSPACE_ID_FRAGMENT = gql`
	${OFFER_ID_FRAGMENT}
	fragment OfferWorkspaceIdFragment on Offer {
		...OfferIdFragment
		workspaceId
	}
`;

export const OFFER_VAT_NUMBER_FRAGMENT = gql`
	${OFFER_ID_FRAGMENT}
	fragment OfferVATNumberFragment on Offer {
		...OfferIdFragment
		VATNumber
	}
`;

export const OFFER_LICENSE_START_DATE_FRAGMENT = gql`
	${OFFER_ID_FRAGMENT}
	fragment OfferLicenseStartDateFragment on Offer {
		...OfferIdFragment
		licenseStartDate
	}
`;

export const OFFER_QUOTE_LAYOUT_FRAGMENT = gql`
	${OFFER_ID_FRAGMENT}
	fragment OfferQuoteLayoutFragment on Offer {
		...OfferIdFragment
		quoteLayout
	}
`;

export const OFFER_ADDRESS_FRAGMENT = gql`
	${OFFER_ID_FRAGMENT}
	${ADDRESS_FRAGMENT}
	fragment OfferAddressFragment on Offer {
		...OfferIdFragment
		address {
			...AddressFragment
		}
	}
`;

export const PUBLIC_OFFER_ID_FRAGMENT = gql`
	fragment PublicOfferIdFragment on PublicOffer {
		id
		tokenObj {
			token
		}
	}
`;

export const PUBLIC_OFFER_WORKSPACE_ID_FRAGMENT = gql`
	${PUBLIC_OFFER_ID_FRAGMENT}
	fragment PublicOfferWorkspaceIdFragment on PublicOffer {
		...PublicOfferIdFragment
		workspaceId
	}
`;

export const PUBLIC_OFFER_NAME_FRAGMENT = gql`
	${PUBLIC_OFFER_ID_FRAGMENT}
	fragment PublicOfferNameFragment on PublicOffer {
		...PublicOfferIdFragment
		name
	}
`;

export const PUBLIC_OFFER_VAT_NUMBER_FRAGMENT = gql`
	${PUBLIC_OFFER_ID_FRAGMENT}
	fragment PublicOfferVATNumberFragment on PublicOffer {
		...PublicOfferIdFragment
		VATNumber
	}
`;

export const PUBLIC_OFFER_LICENSE_START_DATE_FRAGMENT = gql`
	${PUBLIC_OFFER_ID_FRAGMENT}
	fragment PublicOfferLicenseStartDateFragment on PublicOffer {
		...PublicOfferIdFragment
		licenseStartDate
	}
`;

export const OFFER_PUBLIC_FRAGMENT = gql`
	${PUBLIC_OFFER_ID_FRAGMENT}
	${PUBLIC_OFFER_NAME_FRAGMENT}
	${PUBLIC_OFFER_WORKSPACE_ID_FRAGMENT}
	${PUBLIC_OFFER_VAT_NUMBER_FRAGMENT}
	${PUBLIC_OFFER_LICENSE_START_DATE_FRAGMENT}
	fragment PublicOfferFragment on PublicOffer {
		...PublicOfferIdFragment
		...PublicOfferNameFragment
		...PublicOfferWorkspaceIdFragment
		...PublicOfferVATNumberFragment
		...PublicOfferLicenseStartDateFragment
		offerPropertiesId
		ownerId
	}
`;

export const OFFER_FULL_FRAGMENT = gql`
	${OFFER_ID_FRAGMENT}
	${OFFER_NAME_FRAGMENT}
	${OFFER_COMPANY_NAME_FRAGMENT}
	${OFFER_OWNER_FRAGMENT}
	${OFFER_WORKSPACE_ID_FRAGMENT}
	${OFFER_TOKEN_FRAGMENT}
	${OFFER_CONTACT_FRAGMENT}
	${LAST_OFFER_VERSION_FRAGMENT}
	${OFFER_QUOTE_LAYOUT_FRAGMENT}
	${OFFER_VAT_NUMBER_FRAGMENT}
	${OFFER_ADDRESS_FRAGMENT}
	${OFFER_LICENSE_START_DATE_FRAGMENT}
	${OFFER_STATUS_FRAGMENT}
	${OFFER_PROPERTIES_FOR_OFFER_FRAGMENT}
	fragment OfferFullFragment on Offer {
		...OfferIdFragment
		...OfferNameFragment
		...OfferCompanyNameFragment
		...OfferOwnerFragment
		...OfferWorkspaceIdFragment
		...OfferTokenFragment
		...OfferQuoteLayoutFragment
		offerContacts {
			...OfferContactFragment
		}
		offerVersions {
			...LastOfferVersionFragment
		}
		...OfferVATNumberFragment
		...OfferAddressFragment
		...OfferLicenseStartDateFragment
		status {
			...OfferStatusFragment
		}
		offerProperties {
			...OfferPropertiesForOfferFragment
		}
	}
`;
