import { gql } from "@apollo/client";
import { DURATION_ENGAGEMENT_RANGE_FRAGMENT } from "./engagementRangeFragment";
import { TERM_PAYMENT_TERM_RANGE_FRAGMENT } from "./paymentTermRangeFragment";
import { SPECIAL_RANGE_NAME_FRAGMENT } from "./specialRange";
import { FROM_TO_VOLUME_RANGE_WITH_VOLUME_DISCOUNT_FRAGMENT } from "./volumeRangeFragment";

export const RANGE_DEPENDENCY_REQUIRED_ID_FRAGMENT = gql`
	fragment RangeDependencyRequiredIdFragment on RangeDependency {
		requiredId
	}
`;

export const RANGE_DEPENDENCY_DESIRED_ID_FRAGMENT = gql`
	fragment RangeDependencyDesiredIdFragment on RangeDependency {
		desiredId
	}
`;

export const RANGE_DEPENDENCY_PARENT_DEPENDENCY_ID_FRAGMENT = gql`
	fragment RangeDependencyParentDependencyIdFragment on RangeDependency {
		parentDependencyId
	}
`;

export const RANGE_DEPENDENCY_IDS_FRAGMENT = gql`
	${RANGE_DEPENDENCY_REQUIRED_ID_FRAGMENT}
	${RANGE_DEPENDENCY_DESIRED_ID_FRAGMENT}
	${RANGE_DEPENDENCY_PARENT_DEPENDENCY_ID_FRAGMENT}
	fragment RangeDependencyIdsFragment on RangeDependency {
		...RangeDependencyRequiredIdFragment
		...RangeDependencyDesiredIdFragment
		...RangeDependencyParentDependencyIdFragment
	}
`;

export const RANGE_DEPENDENCY_REQUIRED_RANGE_IDS_FRAGMENT = gql`
	${RANGE_DEPENDENCY_REQUIRED_ID_FRAGMENT}
	${RANGE_DEPENDENCY_PARENT_DEPENDENCY_ID_FRAGMENT}
	fragment RangeDependencyRequiredRangeIdsFragment on RangeDependency {
		...RangeDependencyRequiredIdFragment
		...RangeDependencyParentDependencyIdFragment
		requiredRange {
			... on VolumeRange {
				id
				index
			}
			... on EngagementRange {
				id
				index
			}
			... on PaymentTermRange {
				id
			}
			... on SpecialRange {
				id
				index
			}
		}
	}
`;

export const RANGE_DEPENDENCY_DESIRED_RANGE_IDS_FRAGMENT = gql`
	${RANGE_DEPENDENCY_DESIRED_ID_FRAGMENT}
	${RANGE_DEPENDENCY_PARENT_DEPENDENCY_ID_FRAGMENT}
	fragment RangeDependencyDesiredRangeIdsFragment on RangeDependency {
		...RangeDependencyDesiredIdFragment
		...RangeDependencyParentDependencyIdFragment
		desiredRange {
			... on VolumeRange {
				id
				index
			}
			... on EngagementRange {
				id
				index
			}
			... on PaymentTermRange {
				id
			}
			... on SpecialRange {
				id
				index
			}
		}
	}
`;

export const RANGE_DEPENDENCY_REQUIRED_RANGE_FRAGMENT = gql`
	${FROM_TO_VOLUME_RANGE_WITH_VOLUME_DISCOUNT_FRAGMENT}
	${DURATION_ENGAGEMENT_RANGE_FRAGMENT}
	${TERM_PAYMENT_TERM_RANGE_FRAGMENT}
	${SPECIAL_RANGE_NAME_FRAGMENT}
	fragment RangeDependencyRequiredRangeFragment on RangeDependency {
		requiredRange {
			... on VolumeRange {
				...FromToVolumeRangeFragment
			}
			... on EngagementRange {
				...DurationEngagementRangeFragment
			}
			... on PaymentTermRange {
				...TermPaymentTermRangeFragment
			}
			... on SpecialRange {
				...SpecialRangeNameFragment
			}
		}
	}
`;

export const RANGE_DEPENDENCY_DESIRED_RANGE_FRAGMENT = gql`
	${FROM_TO_VOLUME_RANGE_WITH_VOLUME_DISCOUNT_FRAGMENT}
	${DURATION_ENGAGEMENT_RANGE_FRAGMENT}
	${TERM_PAYMENT_TERM_RANGE_FRAGMENT}
	${SPECIAL_RANGE_NAME_FRAGMENT}
	${RANGE_DEPENDENCY_DESIRED_ID_FRAGMENT}
	${RANGE_DEPENDENCY_PARENT_DEPENDENCY_ID_FRAGMENT}
	fragment RangeDependencyDesiredRangeFragment on RangeDependency {
		...RangeDependencyDesiredIdFragment
		...RangeDependencyParentDependencyIdFragment
		desiredRange {
			... on VolumeRange {
				...FromToVolumeRangeFragment
			}
			... on EngagementRange {
				...DurationEngagementRangeFragment
			}
			... on PaymentTermRange {
				...TermPaymentTermRangeFragment
			}
			... on SpecialRange {
				...SpecialRangeNameFragment
			}
		}
	}
`;

export const RANGE_DEPENDENCY_FRAGMENT = gql`
	${RANGE_DEPENDENCY_REQUIRED_ID_FRAGMENT}
	${RANGE_DEPENDENCY_DESIRED_ID_FRAGMENT}
	${RANGE_DEPENDENCY_PARENT_DEPENDENCY_ID_FRAGMENT}
	${RANGE_DEPENDENCY_REQUIRED_RANGE_FRAGMENT}
	${RANGE_DEPENDENCY_DESIRED_RANGE_FRAGMENT}
	fragment RangeDependencyFragment on RangeDependency {
		...RangeDependencyRequiredIdFragment
		...RangeDependencyDesiredIdFragment
		...RangeDependencyParentDependencyIdFragment
		...RangeDependencyRequiredRangeFragment
		...RangeDependencyDesiredRangeFragment
	}
`;

export const RANGE_DEPENDENCY_FOR_PRICE_MODEL_FRAGMENT = gql`
	${RANGE_DEPENDENCY_REQUIRED_ID_FRAGMENT}
	${RANGE_DEPENDENCY_DESIRED_ID_FRAGMENT}
	${RANGE_DEPENDENCY_PARENT_DEPENDENCY_ID_FRAGMENT}
	${RANGE_DEPENDENCY_REQUIRED_RANGE_IDS_FRAGMENT}
	${RANGE_DEPENDENCY_DESIRED_RANGE_IDS_FRAGMENT}
	fragment RangeDependencyForPriceModelFragment on RangeDependency {
		...RangeDependencyRequiredIdFragment
		...RangeDependencyDesiredIdFragment
		...RangeDependencyParentDependencyIdFragment
		...RangeDependencyRequiredRangeIdsFragment
		...RangeDependencyDesiredRangeIdsFragment
	}
`;
