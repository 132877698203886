import { gql } from "@apollo/client";

export const ID_CATEGORY_PRICE_MODEL_FRAGMENT = gql`
	fragment IdCategoryPriceModelFragment on CategoryPriceModel {
		id
	}
`;

export const NAME_CATEGORY_PRICE_MODEL_FRAGMENT = gql`
	fragment NameCategoryPriceModelFragment on CategoryPriceModel {
		id
		name
	}
`;

export const INDEX_CATEGORY_PRICE_MODEL_FRAGMENT = gql`
	fragment IndexCategoryPriceModelFragment on CategoryPriceModel {
		id
		index
	}
`;

export const WEIGHT_CATEGORY_PRICE_MODEL_FRAGMENT = gql`
	fragment WeightCategoryPriceModelFragment on CategoryPriceModel {
		id
		weight
	}
`;

export const OPENED_BY_DEFAULT_CATEGORY_PRICE_MODEL_FRAGMENT = gql`
	fragment OpenedByDefaultCategoryPriceModelFragment on CategoryPriceModel {
		id
		openedByDefault(type: $type)
	}
`;

export const CATEGORY_PRICE_MODEL_FEATURE_IDS_FRAGMENT = gql`
	fragment CategoryPriceModelFeatureIdsFragment on CategoryPriceModel {
		id
		priceModelFeatureIds
	}
`;

export const CATEGORY_FRAGMENT = gql`
	${INDEX_CATEGORY_PRICE_MODEL_FRAGMENT}
	${NAME_CATEGORY_PRICE_MODEL_FRAGMENT}
	${WEIGHT_CATEGORY_PRICE_MODEL_FRAGMENT}
	${CATEGORY_PRICE_MODEL_FEATURE_IDS_FRAGMENT}
	fragment CategoryPriceModelFragment on CategoryPriceModel {
		...IndexCategoryPriceModelFragment
		...NameCategoryPriceModelFragment
		...WeightCategoryPriceModelFragment
		...CategoryPriceModelFeatureIdsFragment
		openedByDefault(type: $type)
	}
`;

export const CATEGORY_PUBLIC_PRICE_MODEL_FRAGMENT = gql`
	${ID_CATEGORY_PRICE_MODEL_FRAGMENT}
	${NAME_CATEGORY_PRICE_MODEL_FRAGMENT}
	${INDEX_CATEGORY_PRICE_MODEL_FRAGMENT}
	${OPENED_BY_DEFAULT_CATEGORY_PRICE_MODEL_FRAGMENT}
	fragment CategoryPublicPriceModelFragment on CategoryPriceModel {
		...IdCategoryPriceModelFragment
		...NameCategoryPriceModelFragment
		...IndexCategoryPriceModelFragment
		...OpenedByDefaultCategoryPriceModelFragment
	}
`;
