import { gql } from "@apollo/client";
import {
	USER_ACTIVE_FRAGMENT,
	USER_CURRENCY_FRAGMENT,
	USER_DEFAULT_WORKSPACE_FRAGMENT,
	USER_EMAIL_FRAGMENT,
	USER_FRAGMENT,
	USER_FUNCTION_FRAGMENT,
	USER_IMAGE_PATH_FRAGMENT,
	USER_IS_ROOT_FRAGMENT,
	USER_LANGUAGE_FRAGMENT,
	USER_LASTNAME_FRAGMENT,
	USER_PHONE_FRAGMENT,
	USER_SURNAME_FRAGMENT,
	USER_THEME_FRAGMENT,
	USER_USERNAME_FRAGMENT,
	USER_UUID_FRAGMENT,
} from "@graphQl/fragments/user";
import { ROLE_FRAGMENT } from "@graphQl/fragments/role";

export const GET_USER_THEME = gql`
	${USER_THEME_FRAGMENT}
	query GetUserThemeById {
		getAuth {
			id
			...UserThemeFragment
		}
	}
`;

export const GET_USER_BY_ID_FOR_UPDATED_BY = gql`
	query GetUserByIdForUpdatedBy($id: ID!) {
		getUserById(id: $id) {
			id
			email
			username
			imagePath
		}
	}
`;

export const GET_USER_BY_ID = gql`
	${USER_USERNAME_FRAGMENT}
	${USER_EMAIL_FRAGMENT}
	${USER_SURNAME_FRAGMENT}
	${USER_LASTNAME_FRAGMENT}
	${USER_DEFAULT_WORKSPACE_FRAGMENT}
	${USER_LANGUAGE_FRAGMENT}
	${USER_CURRENCY_FRAGMENT}
	${USER_IMAGE_PATH_FRAGMENT}
	${USER_IS_ROOT_FRAGMENT}
	${USER_PHONE_FRAGMENT}
	${USER_THEME_FRAGMENT}
	${ROLE_FRAGMENT}
	query GetUserById($id: ID!) {
		getUserById(id: $id) {
			...UserUserNameFragment
			...UserEmailFragment
			...UserSurnameFragment
			...UserLastnameFragment
			...UserDefaultWorkspaceFragment
			...UserLanguageFragment
			...UserCurrencyFragment
			...UserImagePathFragment
			...UserIsRootFragment
			...UserPhoneFragment
			...UserThemeFragment
			id
			role {
				...RoleFragment
			}
		}
	}
`;

//TODO: DUY simplifier tous ces fragments
export const GET_AUTH = gql`
	${USER_UUID_FRAGMENT}
	${USER_USERNAME_FRAGMENT}
	${USER_EMAIL_FRAGMENT}
	${USER_SURNAME_FRAGMENT}
	${USER_LASTNAME_FRAGMENT}
	${USER_DEFAULT_WORKSPACE_FRAGMENT}
	${USER_LANGUAGE_FRAGMENT}
	${USER_CURRENCY_FRAGMENT}
	${USER_IMAGE_PATH_FRAGMENT}
	${USER_IS_ROOT_FRAGMENT}
	${USER_PHONE_FRAGMENT}
	${USER_THEME_FRAGMENT}
	${USER_FUNCTION_FRAGMENT}
	${ROLE_FRAGMENT}
	query GetAuth {
		getAuth {
			...UserUUIDFragment
			...UserUserNameFragment
			...UserEmailFragment
			...UserSurnameFragment
			...UserLastnameFragment
			...UserDefaultWorkspaceFragment
			...UserLanguageFragment
			...UserCurrencyFragment
			...UserImagePathFragment
			...UserIsRootFragment
			...UserPhoneFragment
			...UserThemeFragment
			...UserFunctionFragment
			id
			role {
				...RoleFragment
			}
		}
	}
`;

export const GET_AUTH_FOR_LOGIN = gql`
	${USER_UUID_FRAGMENT}
	${USER_USERNAME_FRAGMENT}
	${USER_EMAIL_FRAGMENT}
	${USER_SURNAME_FRAGMENT}
	${USER_LASTNAME_FRAGMENT}
	${USER_DEFAULT_WORKSPACE_FRAGMENT}
	${USER_LANGUAGE_FRAGMENT}
	${USER_CURRENCY_FRAGMENT}
	${USER_IMAGE_PATH_FRAGMENT}
	${USER_IS_ROOT_FRAGMENT}
	${USER_PHONE_FRAGMENT}
	${USER_THEME_FRAGMENT}
	${USER_FUNCTION_FRAGMENT}
	query GetLoginAuth {
		getAuth {
			...UserUUIDFragment
			...UserUserNameFragment
			...UserEmailFragment
			...UserSurnameFragment
			...UserLastnameFragment
			...UserDefaultWorkspaceFragment
			...UserLanguageFragment
			...UserCurrencyFragment
			...UserImagePathFragment
			...UserIsRootFragment
			...UserPhoneFragment
			...UserThemeFragment
			...UserFunctionFragment
			roles {
				id
				name
				workspace {
					id
					active
				}
			}
		}
	}
`;

export const GET_LIGHT_USERS_BY_WORKSPACE_ID = gql`
	${USER_USERNAME_FRAGMENT}
	${USER_EMAIL_FRAGMENT}
	${USER_LANGUAGE_FRAGMENT}
	${USER_CURRENCY_FRAGMENT}
	${USER_IMAGE_PATH_FRAGMENT}
	${USER_IS_ROOT_FRAGMENT}
	${USER_ACTIVE_FRAGMENT}
	query GetLightWorkspaceUsers {
		getWorkspaceUsers {
			...UserUserNameFragment
			...UserEmailFragment
			...UserLanguageFragment
			...UserCurrencyFragment
			...UserImagePathFragment
			...UserIsRootFragment
			...UserActiveFragment
			id
		}
	}
`;

export const GET_USER_FOR_TEAMATE = gql`
	${USER_FRAGMENT}
	query GetUserForTeamateById($id: ID!) {
		getUserById(id: $id) {
			...UserFragment
		}
	}
`;

export const GET_WORKSPACE_USERS = gql`
	${USER_FRAGMENT}
	query GetWorkspaceUsers {
		getWorkspaceUsers {
			...UserFragment
		}
	}
`;

export const GET_WORKSPACE_ROOT_USERS = gql`
	${USER_FRAGMENT}
	query GetWorkspaceRootUsers {
		getWorkspaceRootUsers {
			...UserFragment
		}
	}
`;

export const GET_ROOT_USERS_EMAIL = gql`
	${USER_EMAIL_FRAGMENT}
	query GetRootUsers {
		getRootUsers {
			id
			...UserEmailFragment
		}
	}
`;

export const GET_JUNIOR_ROLES = gql`
	query GetJuniorRoles {
		getJuniorRoles {
			id
			index
			name
			defaultRoleId
			protected
			description
		}
	}
`;

export const GET_FULL_JUNIOR_ROLES = gql`
	${ROLE_FRAGMENT}
	query GetJuniorRoles {
		getJuniorRoles {
			...RoleFragment
		}
	}
`;

export const GET_ALL_WORKSPACE_USER_FOR_FILTER = gql`
	query AllWorkspaceUsersForFilter {
		allWorkspaceUsers {
			id
			email
			username
			imagePath
		}
	}
`;
