import { gql } from "@apollo/client";
import {
	UNIT_METRICS_VOLUME_DISCOUNT_FRAGMENT,
	VOLUME_DISCOUNT_FRAGMENT,
} from "@graphQl/fragments/volumeDiscountFragment";
import { ENGAGEMENT_DISCOUNT_ENGAGEMENT_RANGE_FRAGMENT } from "@graphQl/fragments/engagementDiscountFragment";
import {
	PAYMENT_TERM_DISCOUNT_FRAGMENT,
	PAYMENT_TERM_DISCOUNT_PAYMENT_TERM_RANGE_TERM_FRAGMENT,
} from "@graphQl/fragments/paymentTermDiscountFragment";
import { SPECIAL_DISCOUNT_FRAGMENT } from "@graphQl/fragments/specialDiscount";
import { DEPENDENCY_FOR_PRICE_MODEL_FRAGMENT, DEPENDENCY_FRAGMENT } from "./dependency";
import {
	VARIABLE_TIME_DISCOUNT_FRAGMENT,
	VARIABLE_TIME_DISCOUNT_PAYMENT_TERM_RANGE_ID_FRAGMENT,
	VARIABLE_TIME_DISCOUNT_PAYMENT_TERM_RANGE_TERM_FRAGMENT,
} from "./variableTimeDiscount";

export const ID_DISCOUNT_RULE_FRAGMENT = gql`
	fragment IdDiscountRuleFragment on DiscountRule {
		id
	}
`;

export const PRICE_MODEL_ID_DISCOUNT_RULE_FRAGMENT = gql`
	fragment PriceModelIdDiscountRuleFragment on DiscountRule {
		id
		priceModelId
	}
`;

export const NAME_DISCOUNT_RULE_FRAGMENT = gql`
	fragment NameDiscountRuleFragment on DiscountRule {
		id
		name
	}
`;

export const DESCRIPTION_DISCOUNT_RULE_FRAGMENT = gql`
	fragment DescriptionDiscountRuleFragment on DiscountRule {
		id
		description
	}
`;

export const INDEX_DISCOUNT_RULE_FRAGMENT = gql`
	fragment IndexDiscountRuleFragment on DiscountRule {
		id
		index
	}
`;

export const TYPE_DISCOUNT_RULE_FRAGMENT = gql`
	fragment TypeDiscountRuleFragment on DiscountRule {
		id
		type
	}
`;

export const ACTIVE_DISCOUNT_RULE_FRAGMENT = gql`
	fragment ActiveDiscountRuleFragment on DiscountRule {
		id
		active
	}
`;

export const SHOW_DISCOUNT_DISCOUNT_RULE_FRAGMENT = gql`
	fragment ShowDiscountDiscountRuleFragment on DiscountRule {
		id
		showDiscount
	}
`;

export const SHOW_DETAIL_BOX_DISCOUNT_RULE_FRAGMENT = gql`
	fragment ShowDetailBoxDiscountRuleFragment on DiscountRule {
		id
		showDetailBox
	}
`;

export const IS_DISPLAYED_DISCOUNT_RULE_FRAGMENT = gql`
	fragment IsDisplayedDiscountRuleFragment on DiscountRule {
		id
		isDisplayed
	}
`;

export const MEDIAL_URL_DISCOUNT_RULE_FRAGMENT = gql`
	fragment MediaUrlDiscountRuleFragment on DiscountRule {
		id
		mediaUrl
	}
`;

export const LINK_DISCOUNT_RULE_FRAGMENT = gql`
	fragment LinkDiscountRuleFragment on DiscountRule {
		id
		link
	}
`;

export const DEFAULT_VALUE_DISCOUNT_RULE_FRAGMENT = gql`
	fragment DefaultValueDiscountRuleFragment on DiscountRule {
		id
		defaultValue
	}
`;

export const VOLUME_DISCOUNT_ID_FRAGMENT = gql`
	fragment VolumeDiscountIdFragment on DiscountRule {
		id
		volumeDiscount {
			id
		}
	}
`;

export const DISCOUNT_RULE_DEPENDENCIES_FRAGMENT = gql`
	${DEPENDENCY_FRAGMENT}
	fragment DiscountRuleDependenciesFragment on DiscountRule {
		id
		dependenciesAsRequired {
			...DependencyFragment
		}
		dependenciesAsDesired {
			...DependencyFragment
		}
	}
`;

export const DISCOUNT_RULE_DEPENDENCIES_FOR_PRICE_MODEL_FRAGMENT = gql`
	${DEPENDENCY_FOR_PRICE_MODEL_FRAGMENT}
	fragment DiscountRuleDependenciesForPriceModelFragment on DiscountRule {
		id
		dependenciesAsRequired {
			...DependencyForPriceModelFragment
		}
		dependenciesAsDesired {
			...DependencyForPriceModelFragment
		}
	}
`;

export const DISCOUNT_RULES_FRAGMENT = gql`
	fragment DiscountRulesPriceModelFragment on DiscountRule {
		id
		priceModelId
		name
		index
		type
		discountRuleSectionId
		volumeDiscount {
			id
		}
		engagementDiscount {
			id
		}
		paymentTermDiscount {
			id
		}
		variableTimeDiscount {
			id
		}
		specialDiscount {
			id
			isDiscount
			isSpecialCondition
		}
	}
`;

export const DISCOUNT_RULE_DATA_FOR_CACHE_FRAGMENT = gql`
	${PRICE_MODEL_ID_DISCOUNT_RULE_FRAGMENT}
	${TYPE_DISCOUNT_RULE_FRAGMENT}
	fragment DiscountRuleDataForCacheFragment on DiscountRule {
		...PriceModelIdDiscountRuleFragment
		...TypeDiscountRuleFragment
	}
`;

export const VOLUME_DISCOUNT_UNIT_METRICS_DISCOUNT_RULE_FRAGMENT = gql`
	${UNIT_METRICS_VOLUME_DISCOUNT_FRAGMENT}
	fragment VolumeDiscountUnitMetricsDiscountRuleFragment on DiscountRule {
		id
		volumeDiscount {
			...UnitMetricsDiscountFragment
		}
	}
`;

export const VARIABLE_TIME_DISCOUNT_RULE_FRAGMENT = gql`
	${VARIABLE_TIME_DISCOUNT_PAYMENT_TERM_RANGE_ID_FRAGMENT}
	fragment VariableTimeDiscountRuleFragment on DiscountRule {
		variableTimeDiscount {
			...VariableTimeDiscountPaymentTermRangeIdFragment
		}
	}
`;

export const DISCOUNT_RULE_FOR_PUBLIC_PRICE_MODEL_FRAGMENT = gql`
	${NAME_DISCOUNT_RULE_FRAGMENT}
	${TYPE_DISCOUNT_RULE_FRAGMENT}
	${SHOW_DISCOUNT_DISCOUNT_RULE_FRAGMENT}
	${DESCRIPTION_DISCOUNT_RULE_FRAGMENT}
	${LINK_DISCOUNT_RULE_FRAGMENT}
	${MEDIAL_URL_DISCOUNT_RULE_FRAGMENT}
	${PRICE_MODEL_ID_DISCOUNT_RULE_FRAGMENT}
	${ACTIVE_DISCOUNT_RULE_FRAGMENT}
	${INDEX_DISCOUNT_RULE_FRAGMENT}
	${ENGAGEMENT_DISCOUNT_ENGAGEMENT_RANGE_FRAGMENT}
	${VOLUME_DISCOUNT_FRAGMENT}
	${PAYMENT_TERM_DISCOUNT_FRAGMENT}
	${SPECIAL_DISCOUNT_FRAGMENT}
	${DEFAULT_VALUE_DISCOUNT_RULE_FRAGMENT}
	${DISCOUNT_RULE_DEPENDENCIES_FOR_PRICE_MODEL_FRAGMENT}
	${SHOW_DETAIL_BOX_DISCOUNT_RULE_FRAGMENT}
	${VARIABLE_TIME_DISCOUNT_FRAGMENT}
	${IS_DISPLAYED_DISCOUNT_RULE_FRAGMENT}
	fragment DiscountRuleForPublicPriceModelFragment on DiscountRule {
		...NameDiscountRuleFragment
		...TypeDiscountRuleFragment
		...ShowDiscountDiscountRuleFragment
		...ActiveDiscountRuleFragment
		...IndexDiscountRuleFragment
		...PriceModelIdDiscountRuleFragment
		...DescriptionDiscountRuleFragment
		...LinkDiscountRuleFragment
		...MediaUrlDiscountRuleFragment
		...DefaultValueDiscountRuleFragment
		...ShowDetailBoxDiscountRuleFragment
		...IsDisplayedDiscountRuleFragment
		engagementDiscount {
			...EngagementDiscountEngagementRangeFragment
		}
		volumeDiscount {
			...VolumeDiscountFragment
		}
		paymentTermDiscount {
			...PaymentTermDiscountFragment
		}
		specialDiscount {
			...SpecialDiscountFragment
		}
		variableTimeDiscount {
			...VariableTimeDiscountFragment
		}
		...DiscountRuleDependenciesForPriceModelFragment
	}
`;

export const USED_TIME_METRICS_FROM_DISCOUNT_RULE_FRAGMENT = gql`
	${PAYMENT_TERM_DISCOUNT_PAYMENT_TERM_RANGE_TERM_FRAGMENT}
	${VARIABLE_TIME_DISCOUNT_PAYMENT_TERM_RANGE_TERM_FRAGMENT}
	${PRICE_MODEL_ID_DISCOUNT_RULE_FRAGMENT}

	fragment UsedTimeMetricsFromDiscountRuleFragment on DiscountRule {
		...PriceModelIdDiscountRuleFragment
		paymentTermDiscount {
			...PaymentTermDiscountPaymentTermRangeTermFragment
		}
		variableTimeDiscount {
			...VariableTimeDiscountPaymentTermRangeTermFragment
		}
	}
`;
