import { gql } from "@apollo/client";
import { SUMMARY_PRICE_FRAGMENT } from "@graphQl/fragments/summaryPrice";
import { OFFER_CONTACT_EMAIL_FRAGMENT } from "./offerContact";

export const OFFER_VERSION_QUOTE_ID_FRAGMENT = gql`
	fragment OfferVersionQuoteIdFragment on OfferVersionQuote {
		id
	}
`;

export const OFFER_VERSION_IS_SIGNABLE_FRAGMENT = gql`
	${OFFER_VERSION_QUOTE_ID_FRAGMENT}
	fragment OfferVersionQuoteIsSignableFragment on OfferVersionQuote {
		...OfferVersionQuoteIdFragment
		isSignable
	}
`;

export const OFFER_VERSION_IS_PAYABLE_FRAGMENT = gql`
	${OFFER_VERSION_QUOTE_ID_FRAGMENT}
	fragment OfferVersionQuoteIsPayableFragment on OfferVersionQuote {
		...OfferVersionQuoteIdFragment
		isPayable
	}
`;

export const OFFER_VERSION_CREATED_AT_FRAGMENT = gql`
	${OFFER_VERSION_QUOTE_ID_FRAGMENT}
	fragment OfferVersionQuoteCreatedAtFragment on OfferVersionQuote {
		...OfferVersionQuoteIdFragment
		createdAt
	}
`;

export const OFFER_VERSION_OFFER_VERSION_ID_FRAGMENT = gql`
	${OFFER_VERSION_QUOTE_ID_FRAGMENT}
	fragment OfferVersionQuoteOfferVersionIdFragment on OfferVersionQuote {
		...OfferVersionQuoteIdFragment
		offerVersionId
	}
`;

export const OFFER_VERSION_QUOTE_PDF_LINK_FRAGMENT = gql`
	${OFFER_VERSION_QUOTE_ID_FRAGMENT}
	fragment OfferVersionQuotePdfLinkFragment on OfferVersionQuote {
		...OfferVersionQuoteIdFragment
		pdfLink
	}
`;

export const OFFER_VERSION_QUOTE_SIGNED_PDF_LINK_FRAGMENT = gql`
	${OFFER_VERSION_QUOTE_ID_FRAGMENT}
	fragment OfferVersionQuoteSignedPdfLinkFragment on OfferVersionQuote {
		...OfferVersionQuoteIdFragment
		pdfLink
		signedPdfLink
	}
`;

export const OFFER_VERSION_QUOTE_EMAIL_FRAGMENT = gql`
	${OFFER_VERSION_QUOTE_ID_FRAGMENT}
	fragment OfferVersionQuoteEmailFragment on OfferVersionQuote {
		...OfferVersionQuoteIdFragment
		email
	}
`;

export const OFFER_VERSION_QUOTE_SIGNED_AT_FRAGMENT = gql`
	${OFFER_VERSION_QUOTE_ID_FRAGMENT}
	fragment OfferVersionQuoteSignedAtFragment on OfferVersionQuote {
		...OfferVersionQuoteIdFragment
		signedAt
	}
`;

export const OFFER_VERSION_QUOTE_PRICES_FRAGMENT = gql`
	${OFFER_VERSION_QUOTE_ID_FRAGMENT}
	${SUMMARY_PRICE_FRAGMENT}
	fragment OfferVersionQuotePricesFragment on OfferVersionQuote {
		...OfferVersionQuoteIdFragment
		prices {
			...SummaryPriceFragment
		}
	}
`;

export const OFFER_VERSION_QUOTE_SEND_EMAIL_FRAGMENT = gql`
	${OFFER_VERSION_QUOTE_ID_FRAGMENT}
	fragment OfferVersionQuoteSendEmailFragment on OfferVersionQuote {
		...OfferVersionQuoteIdFragment
		sendEmail
	}
`;

export const OFFER_VERSION_QUOTE_SIGNATURE_LINK_TOKEN_FRAGMENT = gql`
	${OFFER_VERSION_QUOTE_ID_FRAGMENT}
	fragment OfferVersionQuoteSignatureLinkTokenFragment on OfferVersionQuote {
		...OfferVersionQuoteIdFragment
		signatureLinkToken
	}
`;

export const OFFER_VERSION_QUOTE_FRAGMENT = gql`
	${OFFER_VERSION_QUOTE_ID_FRAGMENT}
	${OFFER_VERSION_CREATED_AT_FRAGMENT}
	${OFFER_VERSION_OFFER_VERSION_ID_FRAGMENT}
	${OFFER_VERSION_QUOTE_PDF_LINK_FRAGMENT}
	${OFFER_VERSION_QUOTE_EMAIL_FRAGMENT}
	${OFFER_VERSION_QUOTE_PRICES_FRAGMENT}
	${OFFER_VERSION_QUOTE_SIGNED_PDF_LINK_FRAGMENT}
	${OFFER_VERSION_QUOTE_SEND_EMAIL_FRAGMENT}
	${OFFER_VERSION_QUOTE_SIGNED_AT_FRAGMENT}
	${OFFER_VERSION_QUOTE_SIGNATURE_LINK_TOKEN_FRAGMENT}
	${OFFER_VERSION_IS_SIGNABLE_FRAGMENT}
	${OFFER_VERSION_IS_PAYABLE_FRAGMENT}
	${OFFER_CONTACT_EMAIL_FRAGMENT}
	fragment OfferVersionQuoteFragment on OfferVersionQuote {
		...OfferVersionQuoteIdFragment
		...OfferVersionQuoteCreatedAtFragment
		...OfferVersionQuoteOfferVersionIdFragment
		...OfferVersionQuotePdfLinkFragment
		...OfferVersionQuoteSignedPdfLinkFragment
		...OfferVersionQuoteEmailFragment
		...OfferVersionQuotePricesFragment
		...OfferVersionQuoteSendEmailFragment
		...OfferVersionQuoteSignedAtFragment
		...OfferVersionQuoteSignatureLinkTokenFragment
		...OfferVersionQuoteIsSignableFragment
		...OfferVersionQuoteIsPayableFragment
		signedBy {
			...OfferContactEmailFragment
		}
	}
`;
