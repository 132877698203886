import { gql } from "@apollo/client";
import {
	PUBLIC_SELECT_OPTION_FRAGMENT,
	SELECT_OPTION_FRAGMENT,
	SELECT_OPTION_INDEX_FRAGMENT,
} from "@graphQl/fragments/selectOption";
import {
	NUMERICAL_RANGE_FRAGMENT,
	NUMERICAL_RANGE_INDEX_FRAGMENT,
	PUBLIC_NUMERICAL_RANGE_FRAGMENT,
} from "@graphQl/fragments/numericalRange";
import {
	PUBLIC_SWITCH_OPTION_FRAGMENT,
	SWITCH_OPTION_FRAGMENT,
	SWITCH_OPTION_INDEX_AND_SWITCH_ID_FRAGMENT,
} from "@graphQl/fragments/switchOption";
import {
	LEVEL_DATA_PACK_COST_FRAGMENT,
	LEVEL_DATA_PACK_ERRORS_FRAGMENT,
	LEVEL_DATA_PACK_EXTRA_FEE_COST_FRAGMENT,
	LEVEL_DATA_PACK_EXTRA_FEE_FRAGMENT,
	LEVEL_DATA_PACK_FRAGMENT,
	LEVEL_DATA_PACK_PRICE_FRAGMENT,
	PUBLIC_LEVEL_DATA_PACK_FRAGMENT,
} from "@graphQl/fragments/levelDataPack";

export const LEVEL_DATA_PRICE_MODEL_FEATURE_ID_FRAGMENT = gql`
	fragment LevelDataPriceModelFeatureIdFragment on LevelData {
		id
		priceModelFeatureId
		uuid
	}
`;

export const LEVEL_DATA_SELECT_OPTION_ID_FRAGMENT = gql`
	fragment LevelDataSelectOptionIdFragment on LevelData {
		id
		selectOptionId
	}
`;

export const LEVEL_DATA_NUMERICAL_RANGE_ID_FRAGMENT = gql`
	fragment LevelDataNumericalRangeIdFragment on LevelData {
		id
		numericalRangeId
	}
`;

export const LEVEL_DATA_SWITCH_OPTION_ID_FRAGMENT = gql`
	fragment LevelDataSwitchOptionIdFragment on LevelData {
		id
		switchOptionId
	}
`;

export const LEVEL_DATA_SCORE_FRAGMENT = gql`
	fragment LevelDataScoreFragment on LevelData {
		id
		score
	}
`;

export const LEVEL_DATA_PACKS_FRAGMENT = gql`
	${LEVEL_DATA_PACK_FRAGMENT}
	fragment LevelDataPacksFragment on LevelData {
		id
		levelDataPacks {
			...LevelDataPackFragment
		}
	}
`;

export const PUBLIC_LEVEL_DATA_PACKS_FRAGMENT = gql`
	${PUBLIC_LEVEL_DATA_PACK_FRAGMENT}
	fragment PublicLevelDataPacksFragment on LevelData {
		id
		levelDataPacks {
			...PublicLevelDataPackFragment
		}
	}
`;

export const LEVEL_DATA_PACKS_COST_AND_PRICE_FRAGMENT = gql`
	${LEVEL_DATA_PACK_COST_FRAGMENT}
	${LEVEL_DATA_PACK_EXTRA_FEE_COST_FRAGMENT}
	${LEVEL_DATA_PACK_PRICE_FRAGMENT}
	${LEVEL_DATA_PACK_EXTRA_FEE_FRAGMENT}
	${SELECT_OPTION_INDEX_FRAGMENT}
	${NUMERICAL_RANGE_INDEX_FRAGMENT}
	${SWITCH_OPTION_INDEX_AND_SWITCH_ID_FRAGMENT}
	fragment LevelDataPacksCostAndPriceFragment on LevelData {
		id
		uuid
		levelDataPacks {
			...LevelDataPackCostFragment
			...LevelDataPackExtraFeeCostFragment
			...LevelDataPackPriceFragment
			...LevelDataPackExtraFeeFragment
		}
		value {
			... on SelectOption {
				...SelectOptionIndexFragment
			}
			... on NumericalRange {
				...NumericalRangeIndexFragment
			}
			... on SwitchOption {
				...SwitchOptionIndexAndSwitchIdFragment
			}
		}
	}
`;

export const LEVEL_DATA_VALUE_FRAGMENT = gql`
	${SELECT_OPTION_FRAGMENT}
	${NUMERICAL_RANGE_FRAGMENT}
	${SWITCH_OPTION_FRAGMENT}
	fragment LevelDataValueFragment on LevelData {
		id
		uuid
		value {
			... on SelectOption {
				...SelectOptionFragment
			}
			... on NumericalRange {
				...NumericalRangeFragment
			}
			... on SwitchOption {
				...SwitchOptionFragment
			}
		}
	}
`;

export const PUBLIC_LEVEL_DATA_VALUE_FRAGMENT = gql`
	${PUBLIC_SELECT_OPTION_FRAGMENT}
	${PUBLIC_NUMERICAL_RANGE_FRAGMENT}
	${PUBLIC_SWITCH_OPTION_FRAGMENT}
	fragment PublicLevelDataValueFragment on LevelData {
		id
		uuid
		value {
			... on SelectOption {
				...PublicSelectOptionFragment
			}
			... on NumericalRange {
				...PublicNumericalRangeFragment
			}
			... on SwitchOption {
				...PublicSwitchOptionFragment
			}
		}
	}
`;

export const LEVEL_DATA_WARNINGS_FRAGMENT = gql`
	fragment LevelDataWarningsFragment on LevelData {
		id
		warnings {
			id
			score
		}
	}
`;

export const LEVEL_DATA_VALUE_INDEX_AND_TYPE_ID_FRAGMENT = gql`
	${SELECT_OPTION_INDEX_FRAGMENT}
	${NUMERICAL_RANGE_INDEX_FRAGMENT}
	${SWITCH_OPTION_INDEX_AND_SWITCH_ID_FRAGMENT}
	${LEVEL_DATA_SELECT_OPTION_ID_FRAGMENT}
	${LEVEL_DATA_NUMERICAL_RANGE_ID_FRAGMENT}
	${LEVEL_DATA_SCORE_FRAGMENT}
	fragment LevelDataValueIndexAndTypeIdFragment on LevelData {
		id
		uuid
		...LevelDataSelectOptionIdFragment
		...LevelDataNumericalRangeIdFragment
		...LevelDataScoreFragment
		levelDataPacks {
			levelDataId
			packId
			isBoosterAllowed
			price
			extraFee
			extraFeeCost
			cost
		}
		value {
			... on SelectOption {
				...SelectOptionIndexFragment
				value
				description
			}
			... on NumericalRange {
				...NumericalRangeIndexFragment
				from
				name
				description
				isUnlimited
				errors {
					id
					from
				}
			}
			... on SwitchOption {
				...SwitchOptionIndexAndSwitchIdFragment
				description
				value
			}
		}
	}
`;

export const LEVEL_DATA_LEVEL_DATA_PACK_ERRORS_ONLY_FRAGMENT = gql`
	${LEVEL_DATA_PACK_ERRORS_FRAGMENT}
	fragment LevelDataLevelDataPackErrorsOnlyFragment on LevelData {
		id
		uuid
		levelDataPacks {
			...LevelDataPackErrorsFragment
		}
	}
`;

export const LEVEL_DATA_FRAGMENT = gql`
	${LEVEL_DATA_PRICE_MODEL_FEATURE_ID_FRAGMENT}
	${LEVEL_DATA_SELECT_OPTION_ID_FRAGMENT}
	${LEVEL_DATA_NUMERICAL_RANGE_ID_FRAGMENT}
	${LEVEL_DATA_SWITCH_OPTION_ID_FRAGMENT}
	${LEVEL_DATA_SCORE_FRAGMENT}
	${LEVEL_DATA_VALUE_FRAGMENT}
	${LEVEL_DATA_PACKS_FRAGMENT}
	fragment LevelDataFragment on LevelData {
		id
		uuid
		...LevelDataPriceModelFeatureIdFragment
		...LevelDataSelectOptionIdFragment
		...LevelDataNumericalRangeIdFragment
		...LevelDataSwitchOptionIdFragment
		...LevelDataScoreFragment
		...LevelDataValueFragment
		...LevelDataPacksFragment
	}
`;

export const PUBLIC_LEVEL_DATA_FOR_LEVEL_FRAGMENT = gql`
	${LEVEL_DATA_PRICE_MODEL_FEATURE_ID_FRAGMENT}
	${LEVEL_DATA_SELECT_OPTION_ID_FRAGMENT}
	${LEVEL_DATA_NUMERICAL_RANGE_ID_FRAGMENT}
	${LEVEL_DATA_SWITCH_OPTION_ID_FRAGMENT}
	${LEVEL_DATA_SCORE_FRAGMENT}
	${PUBLIC_LEVEL_DATA_VALUE_FRAGMENT}
	fragment PublicLevelDataForLevelFragment on LevelData {
		id
		uuid
		...LevelDataPriceModelFeatureIdFragment
		...LevelDataSelectOptionIdFragment
		...LevelDataNumericalRangeIdFragment
		...LevelDataSwitchOptionIdFragment
		...LevelDataScoreFragment
		...PublicLevelDataValueFragment
	}
`;

export const PUBLIC_LEVEL_DATA_FRAGMENT = gql`
	${LEVEL_DATA_PRICE_MODEL_FEATURE_ID_FRAGMENT}
	${LEVEL_DATA_SELECT_OPTION_ID_FRAGMENT}
	${LEVEL_DATA_NUMERICAL_RANGE_ID_FRAGMENT}
	${LEVEL_DATA_SWITCH_OPTION_ID_FRAGMENT}
	${LEVEL_DATA_SCORE_FRAGMENT}
	${PUBLIC_LEVEL_DATA_VALUE_FRAGMENT}
	${PUBLIC_LEVEL_DATA_PACKS_FRAGMENT}
	fragment PublicLevelDataFragment on LevelData {
		id
		uuid
		...LevelDataPriceModelFeatureIdFragment
		...LevelDataSelectOptionIdFragment
		...LevelDataNumericalRangeIdFragment
		...LevelDataSwitchOptionIdFragment
		...LevelDataScoreFragment
		...PublicLevelDataValueFragment
		...PublicLevelDataPacksFragment
	}
`;

export const LEVEL_DATA_FOR_SIMULATOR_FRAGMENT = gql`
	${LEVEL_DATA_PRICE_MODEL_FEATURE_ID_FRAGMENT}
	${LEVEL_DATA_SELECT_OPTION_ID_FRAGMENT}
	${LEVEL_DATA_NUMERICAL_RANGE_ID_FRAGMENT}
	${LEVEL_DATA_SWITCH_OPTION_ID_FRAGMENT}
	${LEVEL_DATA_PACKS_FRAGMENT}
	fragment LevelDataForSimulatorFragment on LevelData {
		id
		uuid
		...LevelDataPriceModelFeatureIdFragment
		...LevelDataSelectOptionIdFragment
		...LevelDataNumericalRangeIdFragment
		...LevelDataSwitchOptionIdFragment
		...LevelDataPacksFragment
	}
`;
