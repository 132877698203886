import { gql } from "@apollo/client";
import {
	PAYMENT_TERM_RANGE_FRAGMENT,
	TERM_PAYMENT_TERM_RANGE_FRAGMENT,
} from "@graphQl/fragments/paymentTermRangeFragment";

export const RULES_SECTION_PAYMENT_TERM_DISCOUNT_FRAGMENT = gql`
	fragment RulesSectionPaymentTermDiscountFragment on PaymentTermDiscount {
		id
		paymentTermRange {
			id
			term
		}
	}
`;

export const PAYMENT_FREQUENCY_REFERENCE_PAYMENT_TERM_DISCOUNT_FRAGMENT = gql`
	fragment PaymentFrequencyReferencePaymentTermDiscountFragment on PaymentTermDiscount {
		id
		paymentFrequencyReference
	}
`;

export const PAYMENT_TERMS_RANGE_REFERENCE_ID_PAYMENT_TERM_DISCOUNT_FRAGMENT = gql`
	fragment PaymentTermsRangeReferenceIdPaymentTermDiscountFragment on PaymentTermDiscount {
		id
		paymentTermsRangeReferenceId
	}
`;

export const IS_LOCKED_PAYMENT_TERM_DISCOUNT_FRAGMENT = gql`
	fragment IsLockedPaymentTermDiscountFragment on PaymentTermDiscount {
		id
		isLocked
	}
`;

export const PAYMENT_TERM_DISCOUNT_FRAGMENT = gql`
	${PAYMENT_FREQUENCY_REFERENCE_PAYMENT_TERM_DISCOUNT_FRAGMENT}
	${PAYMENT_TERMS_RANGE_REFERENCE_ID_PAYMENT_TERM_DISCOUNT_FRAGMENT}
	${PAYMENT_TERM_RANGE_FRAGMENT}
	${IS_LOCKED_PAYMENT_TERM_DISCOUNT_FRAGMENT}
	${TERM_PAYMENT_TERM_RANGE_FRAGMENT}
	fragment PaymentTermDiscountFragment on PaymentTermDiscount {
		id
		uuid
		...PaymentFrequencyReferencePaymentTermDiscountFragment
		...PaymentTermsRangeReferenceIdPaymentTermDiscountFragment
		...IsLockedPaymentTermDiscountFragment
		paymentTermRange {
			...PaymentTermRangeFragment
		}
		defaultSelectedPaymentTermRange {
			...TermPaymentTermRangeFragment
		}
	}
`;

export const PAYMENT_TERM_DISCOUNT_PAYMENT_TERM_RANGE_TERM_FRAGMENT = gql`
	${TERM_PAYMENT_TERM_RANGE_FRAGMENT}
	fragment PaymentTermDiscountPaymentTermRangeTermFragment on PaymentTermDiscount {
		id
		paymentTermRange {
			...TermPaymentTermRangeFragment
		}
	}
`;
