import { gql } from "@apollo/client";

export const SWITCH_OPTION_VALUE_FRAGMENT = gql`
	fragment SwitchOptionValueFragment on SwitchOption {
		id
		value
	}
`;

export const SWITCH_OPTION_INDEX_FRAGMENT = gql`
	fragment SwitchOptionIndexFragment on SwitchOption {
		id
		index
	}
`;

export const SWITCH_OPTION_SWITCH_ID_FRAGMENT = gql`
	fragment SwitchOptionSwitchIdFragment on SwitchOption {
		id
		switchId
	}
`;

export const SWITCH_OPTION_DESCRIPTION_FRAGMENT = gql`
	fragment SwitchOptionDescriptionFragment on SwitchOption {
		id
		description
	}
`;

export const SWITCH_OPTION_INDEX_AND_SWITCH_ID_FRAGMENT = gql`
	${SWITCH_OPTION_SWITCH_ID_FRAGMENT}
	${SWITCH_OPTION_INDEX_FRAGMENT}
	fragment SwitchOptionIndexAndSwitchIdFragment on SwitchOption {
		id
		...SwitchOptionSwitchIdFragment
		...SwitchOptionIndexFragment
	}
`;

export const SWITCH_OPTION_FRAGMENT = gql`
	${SWITCH_OPTION_VALUE_FRAGMENT}
	${SWITCH_OPTION_INDEX_FRAGMENT}
	${SWITCH_OPTION_DESCRIPTION_FRAGMENT}
	${SWITCH_OPTION_SWITCH_ID_FRAGMENT}
	fragment SwitchOptionFragment on SwitchOption {
		id
		...SwitchOptionSwitchIdFragment
		...SwitchOptionValueFragment
		...SwitchOptionIndexFragment
		...SwitchOptionDescriptionFragment
	}
`;

export const PUBLIC_SWITCH_OPTION_FRAGMENT = gql`
	${SWITCH_OPTION_VALUE_FRAGMENT}
	${SWITCH_OPTION_INDEX_FRAGMENT}
	${SWITCH_OPTION_DESCRIPTION_FRAGMENT}
	${SWITCH_OPTION_SWITCH_ID_FRAGMENT}
	fragment PublicSwitchOptionFragment on SwitchOption {
		id
		...SwitchOptionSwitchIdFragment
		...SwitchOptionValueFragment
		...SwitchOptionIndexFragment
		...SwitchOptionDescriptionFragment
	}
`;
