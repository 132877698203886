import { gql } from "@apollo/client";
import {
	CRM_CONNECTION_FRAGMENT,
	CRM_CONNECTION_SERVICE_DETAILS_FRAGMENT,
	CRM_CONNECTION_SERVICE_FRAGMENT,
} from "@src/graphQl/fragments/crmConnection";

export const GET_AVAILABLE_CRM_SERVICES = gql`
	${CRM_CONNECTION_SERVICE_DETAILS_FRAGMENT}
	query GetAvailableCrmServices {
		getAvailableCrmServices {
			...CrmConnectionServiceDetailsFragment
		}
	}
`;

export const GET_SERVICE_BY_ID = gql`
	${CRM_CONNECTION_SERVICE_FRAGMENT}
	query GetAvailableServices($id: ID!) {
		getCrmServiceById(id: $id) {
			...CrmConnectionServiceFragment
		}
	}
`;

export const GET_WORKSPACE_CRM_CONNECTIONS = gql`
	${CRM_CONNECTION_FRAGMENT}
	query GetWorkspaceCrmConnections {
		getWorkspaceCrmConnections {
			...CrmConnectionFragment
		}
	}
`;

export const GET_ALL_PIPELINES = gql`
	query GetAllPipelines($connectionId: ID!) {
		getCrmConnectionById(id: $connectionId) {
			id
			allPipelines {
				id
				name
				stages {
					id
					name
				}
			}
		}
	}
`;

export const GET_CRM_CONNECTION_SETTINGS_SYNC = gql`
	query GetCrmConnectionSettingsSync($connectionId: ID!) {
		getCrmConnectionById(id: $connectionId) {
			id
			syncDeleteDeals
			syncLineItems
			syncExpiryDate
			syncStatus
			syncBidAmount
		}
	}
`;

export const GET_CRM_CONNECTION_FOR_PIPELINE = gql`
	query GetCrmConnectionForPipelineSelect($connectionId: ID!) {
		getCrmConnectionById(id: $connectionId) {
			id
			service {
				id
				name
			}
			allPipelines {
				id
				name
				stages {
					id
					name
				}
			}
		}
	}
`;

export const GET_FAVOURITE_MAPPING_ID = gql`
	query GetFavouriteMappingId($connectionId: ID!) {
		getCrmConnectionById(id: $connectionId) {
			id
			favouriteMappingId
		}
	}
`;

export const GET_CRM_CONNECTION_SETTINGS_SERVICE = gql`
	query GetCrmConnectionSettingsService($connectionId: ID!) {
		getCrmConnectionById(id: $connectionId) {
			id
			service {
				id
				name
				canSyncLineItems
				canSetDealCustomPropertyKey
				canSyncDeleteDeals
				customCardUrl
			}
		}
	}
`;

export const GET_CRM_CONNECTION_SETTINGS_CUSTOM_PROPERTIES = gql`
	query GetCrmConnectionSettingsCustomProperties($connectionId: ID!) {
		getCrmConnectionById(id: $connectionId) {
			id
			dealCustomProperties {
				id
				data
				key
				dealKey
				name
				type
				isActive
			}
		}
	}
`;

export const GET_CRM_CONNECTION_FOR_GENERAL_SETTINGS = gql`
	query GetCrmConnectionGeneralSettings($connectionId: ID!) {
		getCrmConnectionById(id: $connectionId) {
			id
			service {
				id
				name
				canSyncDeleteDeals
				canSyncLineItems
				customCardUrl
			}
			syncDeleteDeals
			syncLineItems
			onlyAddLinkedProduct
			onlyAddPriceProduct
		}
	}
`;

export const GET_SALESFORCE_UPLOAD_STATUS = gql`
	query GetSalesforceUploadStatus($connectionId: ID!, $pid: String!) {
		getSalesforceUploadStatus(connectionId: $connectionId, pid: $pid) {
			id
			status
		}
	}
`;

export const SEARCH_PRODUCTS = gql`
	query SearchProducts($connectionId: ID!, $query: String!) {
		getCrmConnectionById(id: $connectionId) {
			id
			searchProducts(query: $query) {
				id
				name
				description
				price
				currency
				productId
			}
		}
	}
`;
