import { gql } from "@apollo/client";

export const SELECT_OPTION_IS_BOOSTER_ALLOWED_FRAGMENT = gql`
	fragment SelectOptionIsBoosterAllowedFragment on SelectOption {
		id
		isBoosterAllowed
	}
`;

export const SELECT_OPTION_VALUE_FRAGMENT = gql`
	fragment SelectOptionValueFragment on SelectOption {
		id
		value
	}
`;

export const SELECT_OPTION_INDEX_FRAGMENT = gql`
	fragment SelectOptionIndexFragment on SelectOption {
		id
		index
	}
`;

export const SELECT_OPTION_SCORE_FRAGMENT = gql`
	fragment SelectOptionScoreFragment on SelectOption {
		id
		score
	}
`;

export const SELECT_OPTION_PRICE_FRAGMENT = gql`
	fragment SelectOptionPriceFragment on SelectOption {
		id
		price
	}
`;

export const SELECT_OPTION_COST_FRAGMENT = gql`
	fragment SelectOptionCostFragment on SelectOption {
		id
		cost
	}
`;

export const SELECT_OPTION_PROFIT_FRAGMENT = gql`
	fragment SelectOptionProfitFragment on SelectOption {
		id
		profit
	}
`;

export const SELECT_OPTION_DESCRIPTION_FRAGMENT = gql`
	fragment SelectOptionDescriptionFragment on SelectOption {
		id
		description
	}
`;

export const SELECT_OPTION_FRAGMENT = gql`
	${SELECT_OPTION_VALUE_FRAGMENT}
	${SELECT_OPTION_INDEX_FRAGMENT}
	${SELECT_OPTION_SCORE_FRAGMENT}
	${SELECT_OPTION_IS_BOOSTER_ALLOWED_FRAGMENT}
	${SELECT_OPTION_PRICE_FRAGMENT}
	${SELECT_OPTION_COST_FRAGMENT}
	${SELECT_OPTION_PROFIT_FRAGMENT}
	${SELECT_OPTION_DESCRIPTION_FRAGMENT}
	fragment SelectOptionFragment on SelectOption {
		id
		selectId
		...SelectOptionValueFragment
		...SelectOptionIndexFragment
		...SelectOptionScoreFragment
		...SelectOptionIsBoosterAllowedFragment
		...SelectOptionPriceFragment
		...SelectOptionCostFragment
		...SelectOptionProfitFragment
		...SelectOptionDescriptionFragment
	}
`;

export const PUBLIC_SELECT_OPTION_FRAGMENT = gql`
	${SELECT_OPTION_VALUE_FRAGMENT}
	${SELECT_OPTION_INDEX_FRAGMENT}
	${SELECT_OPTION_SCORE_FRAGMENT}
	${SELECT_OPTION_IS_BOOSTER_ALLOWED_FRAGMENT}
	${SELECT_OPTION_PRICE_FRAGMENT}
	${SELECT_OPTION_COST_FRAGMENT}
	${SELECT_OPTION_DESCRIPTION_FRAGMENT}
	fragment PublicSelectOptionFragment on SelectOption {
		id
		selectId
		...SelectOptionValueFragment
		...SelectOptionIndexFragment
		...SelectOptionScoreFragment
		...SelectOptionIsBoosterAllowedFragment
		...SelectOptionPriceFragment
		...SelectOptionCostFragment
		...SelectOptionDescriptionFragment
	}
`;
