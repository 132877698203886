import { gql } from "@apollo/client";
import { PAYMENT_TERM_RANGE_DISCOUNT_FRAGMENT } from "@graphQl/fragments/paymentTermRangeDiscount";

export const IS_DEFAULT_SELECTED_PAYMENT_TERM_RANGE_FRAGMENT = gql`
	fragment IsDefaultSelectedPaymentTermRangeFragment on PaymentTermRange {
		id
		isDefaultSelected
	}
`;

export const TERM_PAYMENT_TERM_RANGE_FRAGMENT = gql`
	fragment TermPaymentTermRangeFragment on PaymentTermRange {
		id
		term
	}
`;

export const DESCRIPTION_PAYMENT_TERM_RANGE_FRAGMENT = gql`
	fragment DescriptionPaymentTermRangeFragment on PaymentTermRange {
		id
		description
	}
`;

export const PAYMENT_TERM_DISCOUNT_ID_PAYMENT_TERM_RANGE_FRAGMENT = gql`
	fragment PaymentTermDiscountIdPaymentTermRangeFragment on PaymentTermRange {
		id
		paymentTermDiscountId
	}
`;

export const PAYMENT_TERM_RANGE_DEPENDENCIES_FRAGMENT = gql`
	fragment PaymentTermRangeDependenciesFramgent on PaymentTermRange {
		id
		dependencies {
			requiredId
			desiredId
			parentDependencyId
			requiredRange {
				... on VolumeRange {
					id
				}
				... on EngagementRange {
					id
				}
				... on PaymentTermRange {
					id
				}
				... on SpecialRange {
					id
				}
			}
			desiredRange {
				... on VolumeRange {
					id
				}
				... on EngagementRange {
					id
				}
				... on PaymentTermRange {
					id
				}
				... on SpecialRange {
					id
				}
			}
		}
	}
`;

export const PAYMENT_TERM_RANGE_FRAGMENT = gql`
	${TERM_PAYMENT_TERM_RANGE_FRAGMENT}
	${PAYMENT_TERM_DISCOUNT_ID_PAYMENT_TERM_RANGE_FRAGMENT}
	${PAYMENT_TERM_RANGE_DISCOUNT_FRAGMENT}
	${PAYMENT_TERM_RANGE_DEPENDENCIES_FRAGMENT}
	${IS_DEFAULT_SELECTED_PAYMENT_TERM_RANGE_FRAGMENT}
	${DESCRIPTION_PAYMENT_TERM_RANGE_FRAGMENT}
	fragment PaymentTermRangeFragment on PaymentTermRange {
		id
		uuid
		...TermPaymentTermRangeFragment
		...PaymentTermDiscountIdPaymentTermRangeFragment
		...PaymentTermRangeDependenciesFramgent
		...IsDefaultSelectedPaymentTermRangeFragment
		...DescriptionPaymentTermRangeFragment
		paymentTermDiscountRange {
			...PaymentTermRangeDiscountFragment
		}
	}
`;
