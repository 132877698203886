import { gql } from "@apollo/client";
import { SPECIAL_RANGE_DISCOUNT_FRAGMENT } from "@graphQl/fragments/specialRangeDiscount";

export const SPECIAL_RANGE_DESCRIPTION_FRAGMENT = gql`
	fragment SpecialRangeDescriptionFragment on SpecialRange {
		id
		description
	}
`;

export const SPECIAL_RANGE_NAME_FRAGMENT = gql`
	fragment SpecialRangeNameFragment on SpecialRange {
		id
		name
		errors {
			name
		}
	}
`;

export const NAME_SPECIAL_RANGE = gql`
	fragment NameSpecialRangeFragment on SpecialRange {
		id
		name
		uuid
	}
`;

export const INDEX_SPECIAL_RANGE = gql`
	fragment IndexSpecialRangeFragment on SpecialRange {
		id
		index
	}
`;

export const SPECIAL_RANGE_DEPENDENCIES = gql`
	fragment SpecialRangeDependencies on SpecialRange {
		id
		dependencies {
			requiredId
			desiredId
			parentDependencyId
			requiredRange {
				... on VolumeRange {
					id
				}
				... on EngagementRange {
					id
				}
				... on PaymentTermRange {
					id
				}
				... on SpecialRange {
					id
				}
			}
			desiredRange {
				... on VolumeRange {
					id
				}
				... on EngagementRange {
					id
				}
				... on PaymentTermRange {
					id
				}
				... on SpecialRange {
					id
				}
			}
		}
	}
`;

export const SPECIAL_RANGE_FRAGMENT = gql`
	${SPECIAL_RANGE_DISCOUNT_FRAGMENT}
	${INDEX_SPECIAL_RANGE}
	${NAME_SPECIAL_RANGE}
	${SPECIAL_RANGE_DEPENDENCIES}
	fragment SpecialRangeFragment on SpecialRange {
		id
		uuid
		...IndexSpecialRangeFragment
		...NameSpecialRangeFragment
		...SpecialRangeDependencies
		specialDiscountId
		description
		specialDiscountRange {
			...SpecialRangeDiscountFragment
		}
	}
`;

export const IS_DEFAULT_SELECTED_SPECIAL_RANGE_FRAGMENT = gql`
	fragment IsDefaultSelectedSpecialRangeFragment on SpecialRange {
		id
		isDefaultSelected
	}
`;
