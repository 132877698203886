import { gql } from "@apollo/client";

export const OFFER_PROPERTIES_ID_FRAGMENT = gql`
	fragment OfferPropertiesIdFragment on OfferProperties {
		id
	}
`;

export const OFFER_PROPERTIES_OFFER_ID_FRAGMENT = gql`
	${OFFER_PROPERTIES_ID_FRAGMENT}
	fragment OfferPropertiesOfferIdFragment on OfferProperties {
		...OfferPropertiesIdFragment
		offerId
	}
`;

export const OFFER_PROPERTIES_PRICE_MODEL_COMBINATION_ID_FRAGMENT = gql`
	${OFFER_PROPERTIES_ID_FRAGMENT}
	fragment OfferPropertiesPriceModelCombinationIdFragment on OfferProperties {
		...OfferPropertiesIdFragment
		priceModelCombinationId
	}
`;

export const OFFER_PROPERTIES_ENABLE_TC_FRAGMENT = gql`
	${OFFER_PROPERTIES_ID_FRAGMENT}
	fragment OfferPropertiesEnableTcFragment on OfferProperties {
		...OfferPropertiesIdFragment
		enableTc
	}
`;

export const OFFER_PROPERTIES_ENABLE_FOOTER_FRAGMENT = gql`
	${OFFER_PROPERTIES_ID_FRAGMENT}
	fragment OfferPropertiesEnableFooterFragment on OfferProperties {
		...OfferPropertiesIdFragment
		enableFooter
	}
`;

export const OFFER_PROPERTIES_FOOTER_CONTENT_FRAGMENT = gql`
	${OFFER_PROPERTIES_ID_FRAGMENT}
	fragment OfferPropertiesFooterContentFragment on OfferProperties {
		...OfferPropertiesIdFragment
		footerContent
	}
`;

export const OFFER_PROPERTIES_FOOTER_TITLE_FRAGMENT = gql`
	${OFFER_PROPERTIES_ID_FRAGMENT}
	fragment OfferPropertiesFooterTitleFragment on OfferProperties {
		...OfferPropertiesIdFragment
		footerTitle
	}
`;

export const OFFER_PROPERTIES_TC_PDF_LINK_FRAGMENT = gql`
	${OFFER_PROPERTIES_ID_FRAGMENT}
	fragment OfferPropertiesTcPdfLinkFragment on OfferProperties {
		...OfferPropertiesIdFragment
		tcPDFLink
	}
`;

export const OFFER_PROPERTIES_TC_URL_LINK_FRAGMENT = gql`
	${OFFER_PROPERTIES_ID_FRAGMENT}
	fragment OfferPropertiesTcUrlLinkFragment on OfferProperties {
		...OfferPropertiesIdFragment
		tcUrlLink
	}
`;

export const OFFER_PROPERTIES_TC_PDF_NAME_FRAGMENT = gql`
	${OFFER_PROPERTIES_ID_FRAGMENT}
	fragment OfferPropertiesTcPdfNameFragment on OfferProperties {
		...OfferPropertiesIdFragment
		tcPDFName
	}
`;

export const OFFER_PROPERTIES_TC_TYPE_FRAGMENT = gql`
	${OFFER_PROPERTIES_ID_FRAGMENT}
	fragment OfferPropertiesTcTypeFragment on OfferProperties {
		...OfferPropertiesIdFragment
		tcType
	}
`;

export const OFFER_PROPERTIES_TC_BUTTON_TEXT_FRAGMENT = gql`
	${OFFER_PROPERTIES_ID_FRAGMENT}
	fragment OfferPropertiesTcButtonTextFragment on OfferProperties {
		...OfferPropertiesIdFragment
		tcButtonText
	}
`;

export const OFFER_PROPERTIES_ENABLE_DEAL_OWNER_CONTACT_FRAGMENT = gql`
	${OFFER_PROPERTIES_ID_FRAGMENT}
	fragment OfferPropertiesEnableDealOwnerContactFragment on OfferProperties {
		...OfferPropertiesIdFragment
		enableDealOwnerContact
	}
`;

export const OFFER_PROPERTIES_ENABLE_INTRODUCTION_PAGE_FRAGMENT = gql`
	${OFFER_PROPERTIES_ID_FRAGMENT}
	fragment OfferPropertiesEnableIntroductionPageFragment on OfferProperties {
		...OfferPropertiesIdFragment
		enableIntroductionPage
	}
`;

export const OFFER_PROPERTIES_ENABLE_INTRODUCTION_TEXT_FOR_PROSPECT_FRAGMENT = gql`
	${OFFER_PROPERTIES_ID_FRAGMENT}
	fragment OfferPropertiesEnableIntroductionTextForProspectFragment on OfferProperties {
		...OfferPropertiesIdFragment
		enableIntroductionTextForProspect
	}
`;

export const OFFER_PROPERTIES_INTRODUCTION_TEXT_FOR_PROSPECT_FRAGMENT = gql`
	${OFFER_PROPERTIES_ID_FRAGMENT}
	fragment OfferPropertiesIntroductionTextForProspectFragment on OfferProperties {
		...OfferPropertiesIdFragment
		introductionTextForProspect
	}
`;

export const OFFER_PROPERTIES_ENABLE_ATTACHED_PRESENTATION_FRAGMENT = gql`
	${OFFER_PROPERTIES_ID_FRAGMENT}
	fragment OfferPropertiesEnableAttachedPresentationFragment on OfferProperties {
		...OfferPropertiesIdFragment
		enableAttachedPresentation
	}
`;

export const OFFER_PROPERTIES_ATTACHED_PRESENTATION_FRAGMENT = gql`
	${OFFER_PROPERTIES_ID_FRAGMENT}
	fragment OfferPropertiesAttachedPresentationFragment on OfferProperties {
		...OfferPropertiesIdFragment
		attachedPresentation
	}
`;
export const OFFER_PROPERTIES_ATTACHED_PRESENTATION_TITLE_FRAGMENT = gql`
	${OFFER_PROPERTIES_ID_FRAGMENT}
	fragment OfferPropertiesAttachedPresentationTitleFragment on OfferProperties {
		...OfferPropertiesIdFragment
		attachedPresentationTitle
	}
`;

export const OFFER_PROPERTIES_ENABLE_ATTACHED_LINK_FRAGMENT = gql`
	${OFFER_PROPERTIES_ID_FRAGMENT}
	fragment OfferPropertiesEnableAttachedLinkFragment on OfferProperties {
		...OfferPropertiesIdFragment
		enableAttachedLink
	}
`;

export const OFFER_PROPERTIES_ENABLE_CLIENTS_LOGO_FRAGMENT = gql`
	${OFFER_PROPERTIES_ID_FRAGMENT}
	fragment OfferPropertiesEnableClientsLogoFragment on OfferProperties {
		...OfferPropertiesIdFragment
		enableClientsLogo
	}
`;

export const OFFER_PROPERTIES_CLIENTS_LOGO_FRAGMENT = gql`
	${OFFER_PROPERTIES_ID_FRAGMENT}
	fragment OfferPropertiesClientsLogoFragment on OfferProperties {
		...OfferPropertiesIdFragment
		clientsLogo
	}
`;

export const OFFER_PROPERTIES_ATTACHED_LINK_FRAGMENT = gql`
	${OFFER_PROPERTIES_ID_FRAGMENT}
	fragment OfferPropertiesAttachedLinkFragment on OfferProperties {
		...OfferPropertiesIdFragment
		attachedLink
	}
`;
export const OFFER_PROPERTIES_ATTACHED_LINK_TITLE_FRAGMENT = gql`
	${OFFER_PROPERTIES_ID_FRAGMENT}
	fragment OfferPropertiesAttachedLinkTitleFragment on OfferProperties {
		...OfferPropertiesIdFragment
		attachedLinkTitle
	}
`;

export const OFFER_PROPERTIES_NAME_FRAGMENT = gql`
	${OFFER_PROPERTIES_ID_FRAGMENT}
	fragment OfferPropertiesNameFragment on OfferProperties {
		...OfferPropertiesIdFragment
		name
	}
`;

export const OFFER_PROPERTIES_FOR_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	${OFFER_PROPERTIES_ID_FRAGMENT}
	${OFFER_PROPERTIES_PRICE_MODEL_COMBINATION_ID_FRAGMENT}
	${OFFER_PROPERTIES_ENABLE_TC_FRAGMENT}
	${OFFER_PROPERTIES_ENABLE_FOOTER_FRAGMENT}
	${OFFER_PROPERTIES_FOOTER_CONTENT_FRAGMENT}
	${OFFER_PROPERTIES_NAME_FRAGMENT}
	${OFFER_PROPERTIES_ENABLE_DEAL_OWNER_CONTACT_FRAGMENT}
	${OFFER_PROPERTIES_ENABLE_INTRODUCTION_TEXT_FOR_PROSPECT_FRAGMENT}
	${OFFER_PROPERTIES_INTRODUCTION_TEXT_FOR_PROSPECT_FRAGMENT}

	fragment OfferPropertiesForPriceModelCombinationFragment on OfferProperties {
		...OfferPropertiesIdFragment
		...OfferPropertiesPriceModelCombinationIdFragment
		...OfferPropertiesEnableTcFragment
		...OfferPropertiesEnableFooterFragment
		...OfferPropertiesFooterContentFragment
		...OfferPropertiesNameFragment
		...OfferPropertiesEnableDealOwnerContactFragment
		...OfferPropertiesEnableIntroductionTextForProspectFragment
		...OfferPropertiesIntroductionTextForProspectFragment
	}
`;

export const OFFER_PROPERTIES_FOR_OFFER_FRAGMENT = gql`
	${OFFER_PROPERTIES_ID_FRAGMENT}
	${OFFER_PROPERTIES_OFFER_ID_FRAGMENT}
	${OFFER_PROPERTIES_ENABLE_TC_FRAGMENT}
	${OFFER_PROPERTIES_ENABLE_FOOTER_FRAGMENT}
	${OFFER_PROPERTIES_FOOTER_CONTENT_FRAGMENT}
	${OFFER_PROPERTIES_FOOTER_TITLE_FRAGMENT}
	${OFFER_PROPERTIES_TC_PDF_LINK_FRAGMENT}
	${OFFER_PROPERTIES_TC_URL_LINK_FRAGMENT}
	${OFFER_PROPERTIES_TC_PDF_NAME_FRAGMENT}
	${OFFER_PROPERTIES_TC_TYPE_FRAGMENT}
	${OFFER_PROPERTIES_TC_BUTTON_TEXT_FRAGMENT}
	${OFFER_PROPERTIES_NAME_FRAGMENT}
	${OFFER_PROPERTIES_ENABLE_DEAL_OWNER_CONTACT_FRAGMENT}
	${OFFER_PROPERTIES_ENABLE_INTRODUCTION_TEXT_FOR_PROSPECT_FRAGMENT}
	${OFFER_PROPERTIES_INTRODUCTION_TEXT_FOR_PROSPECT_FRAGMENT}
	${OFFER_PROPERTIES_ENABLE_ATTACHED_PRESENTATION_FRAGMENT}
	${OFFER_PROPERTIES_ATTACHED_PRESENTATION_FRAGMENT}
	${OFFER_PROPERTIES_ATTACHED_PRESENTATION_TITLE_FRAGMENT}
	${OFFER_PROPERTIES_ENABLE_ATTACHED_LINK_FRAGMENT}
	${OFFER_PROPERTIES_ATTACHED_LINK_FRAGMENT}
	${OFFER_PROPERTIES_ATTACHED_LINK_TITLE_FRAGMENT}
	${OFFER_PROPERTIES_ENABLE_CLIENTS_LOGO_FRAGMENT}
	${OFFER_PROPERTIES_CLIENTS_LOGO_FRAGMENT}
	fragment OfferPropertiesForOfferFragment on OfferProperties {
		...OfferPropertiesIdFragment
		...OfferPropertiesOfferIdFragment
		...OfferPropertiesEnableTcFragment
		...OfferPropertiesEnableFooterFragment
		...OfferPropertiesFooterContentFragment
		...OfferPropertiesFooterTitleFragment
		...OfferPropertiesTcPdfLinkFragment
		...OfferPropertiesTcUrlLinkFragment
		...OfferPropertiesTcPdfNameFragment
		...OfferPropertiesTcTypeFragment
		...OfferPropertiesTcButtonTextFragment
		...OfferPropertiesNameFragment
		...OfferPropertiesEnableDealOwnerContactFragment
		...OfferPropertiesEnableIntroductionTextForProspectFragment
		...OfferPropertiesIntroductionTextForProspectFragment
		...OfferPropertiesEnableAttachedPresentationFragment
		...OfferPropertiesAttachedPresentationFragment
		...OfferPropertiesAttachedPresentationTitleFragment
		...OfferPropertiesEnableAttachedLinkFragment
		...OfferPropertiesAttachedLinkFragment
		...OfferPropertiesAttachedLinkTitleFragment
		...OfferPropertiesEnableClientsLogoFragment
		...OfferPropertiesClientsLogoFragment
	}
`;
